import React, { useEffect } from 'react';
import NProgress from 'nprogress';
import { Box, makeStyles } from '@material-ui/core';
import clsx from 'clsx';

const useStyles = makeStyles((theme) => ({
  root: {
    alignItems: 'center',
    backgroundColor: theme.palette.background.default,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: theme.spacing(3)
  }
}));

function LoadingScreen({ height = '100%', divHeight = '100%' }) {
  const classes = useStyles();

  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    <div className={clsx(classes.root)} style={{ height: divHeight }}>
      <Box
        width={400}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'center',
          height
        }}
      >
        <img
          alt="loading"
          src="/static/editor-1s-47px.gif"
          width={47}
          height={47}
        />
      </Box>
    </div>
  );
}

export default LoadingScreen;
