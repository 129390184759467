import React, { useState, useEffect } from 'react';
import { NavLink as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Collapse,
  Divider,
  ListItem,
  makeStyles,
  List
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import { Link } from 'react-router-dom';

const useStyles = makeStyles(theme => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0
  },
  button: {
    color: '#f4f6f8',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  buttonLeaf: {
    color: '#f4f6f8',
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    },
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    fontSize: '1.2rem',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  active: {
    color: '#ffffff',
    textDecoration: 'underline',
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    },
    '& $icon': {
      color: theme.palette.secondary.main
    }
  },
  homeLink: {
    textDecoration: 'none',
    color: '#fff',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));

function NavItem({
  title,
  href,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);
  console.log(children);
  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  let paddingLeft = 8;

  if (depth > 0) {
    paddingLeft = 8 + 8 * depth;
  }

  const style = { paddingLeft };
  //console.log('rest data : ',rest);

  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button className={classes.button} style={style}>
          {Icon && <Icon className={classes.icon} size="20" />}
          <Link to="/app" className={classes.homeLink}>
            <span className={classes.title}>{title}</span>
          </Link>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
              onClick={handleToggle}
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
              onClick={handleToggle}
            />
          )}
        </Button>
        <Collapse in={open}>{children}</Collapse>
      </ListItem>
    );
  }
  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={title}
      {...rest}
    >
      <Box display="flex" flexDirection="column" width="100%">
        <Button
          activeClassName={classes.active}
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          component={RouterLink}
          exact
          style={style}
          to={href}
        >
          {Icon && (
            <Icon
              className={classes.icon}
              size="20"
              style={{ color: rest.color }}
            />
          )}
          <span className={classes.title}>{title}</span>
          {Info && <Info className={classes.info} />}
        </Button>
        {rest.subItems && (
          <Box display="flex" style={style}>
            <Box pr={2} pl={1}>
              <Divider
                orientation="vertical"
                variant="fullWidth"
                style={{
                  backgroundColor: rest.color,
                  width: '3px',
                  borderRadius: '16px'
                }}
              />
            </Box>
            <List>
              {rest.subItems.map(item => (
                <ListItem
                  className={clsx(classes.itemLeaf, className)}
                  disableGutters
                  key={Math.random()}
                  {...rest}
                >
                  <Button
                    style={{
                      textAlign: 'left',
                      justifyContent: 'end',
                      fontSize: '12px',
                      color: '#f4f6f8'
                    }}
                    component={RouterLink}
                    to={item.href}
                  >
                    {item.title}
                  </Button>
                </ListItem>
              ))}
            </List>
          </Box>
        )}
      </Box>
    </ListItem>
  );
}

NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
