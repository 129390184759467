import axios from 'axios';
import authService from 'src/services/authService';
import { apiService } from 'src/services/APIServices';
import userAPIServices from 'src/services/userAPIServices';
import { goOffline } from './chatActions';
import Axios from 'axios';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';
export const COVER_SAVE_SUCCESS = '@account/cover-save-success';
export const COVER_SAVE_FAIL = '@account/cover-save-fail';
export const AVATAR_UPDATE_SUCCESS = '@account/avatar=save=success';
export const SET_HELP_MODAL = '@account/set-help-modal';
export const UNSET_HELP_MODAL = '@account/unset-help-modal';

export function login(username, password, role) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });

      var user = await authService.loginWithEmailAndPassword(
        username,
        password,
        role
      );

      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user: user
        }
      });

      axios.get(apiService.getSystemAccessByUserId).then((res) => {
        if (res.data == 1) {
          let request;
          request = axios.get(`${apiService.getButtonLink}/${user?.user_type}`);

          request
            .then((result) => {
              if (result.data?._id) {
                dispatch({
                  type: SET_HELP_MODAL,
                  payload: {
                    help: {
                      HelpModal: result.data?.link_site?.NL,
                      isModalOpen: true
                    }
                  }
                });
              }
            })
            .catch((error) => {
              throw error;
            });
        }
      });
    } catch (error) {
      console.log('log: error', error);
      dispatch({ type: LOGIN_FAILURE, payload: error });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) =>
    dispatch({
      type: SILENT_LOGIN,
      payload: {
        user
      }
    });
}

export function logout() {
  return async (dispatch) => {
    authService.logout();
    goOffline();

    dispatch({
      type: LOGOUT
    });
  };
}

export function register() {
  return true;
}

export function updateProfile(update) {
  const request = axios.post(apiService.updateprofile, update);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: UPDATE_PROFILE,
        payload: response.data
      })
    );
  };
}

export function updateCover(result) {
  return async (dispatch) => {
    try {
      dispatch({
        type: COVER_SAVE_SUCCESS,
        payload: {
          CoverPic: result.coverPicture
        }
      });
    } catch (error) {
      dispatch({ type: COVER_SAVE_FAIL });
      throw error;
    }
  };
}

export function updateAvatar(result) {
  return async (dispatch) => {
    try {
      dispatch({
        type: AVATAR_UPDATE_SUCCESS,
        payload: {
          avatar: result.avatar
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
}

//logout request
export function sessionLogout() {
  let request;
  request = axios.get(`${apiService.activeUser}/logout`);

  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function showHelpModal(button_name) {
  console.log('log: buttonName', button_name);
  let request;

  request = axios.get(`${apiService.getButtonLink}/${button_name}`);

  return async (dispatch) => {
    return request
      .then((result) => {
        if (result.data?._id) {
          console.log('log: result', result.data);
          dispatch({
            type: SET_HELP_MODAL,
            payload: {
              help: {
                HelpModal: result.data?.link_site?.NL,
                isModalOpen: true
              }
            }
          });
        }
      })
      .catch((error) => {
        console.log('log: error', error);
        throw error;
      });
  };
}

export function getCompanyUsers() {
  let request = axios.get(userAPIServices.getCompanyUsers);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyUser() {
  let request = axios.get(userAPIServices.getCompanyUsers);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export async function availableNickname(nickname) {
  const request = Axios.get(`${userAPIServices.availableNickname}/${nickname}`);

  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getUserRoles(username) {
  try {
    const result = await axios.get(
      `${userAPIServices.getUserRoles}/${username}`
    );
    return result.data;
  } catch (error) {
    console.log('log:error', error);
    return error.response.data;
  }
}
