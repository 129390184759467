/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  Shield as ShieldIcon,
  Edit as EditIcon,
  MessageCircle as MessageCircleIcon,
  Users as UsersIcon,
  Home as HomeIcon,
  FileText as FileTextIcon,
  Award as AwardIcon
} from 'react-feather';
import {
  MailOutline as MailOutlineIcon,
  School as SchoolIcon,
  TrendingUp as TrendingUpIcon,
  Info as InfoIcon,
  LabelImportant as LabelImportantIcon
} from '@material-ui/icons';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import { apiService } from 'src/services/APIServices';
import BinocularIcon from 'src/components/Icons/BinocularIcon';
import ChecklistIcon from 'src/components/Icons/ChecklistIcon';
import subMenu from 'src/constants/subMenu';

function renderNavItems({ items, ...rest }) {
  return (
    <List disablePadding key={`1-list`}>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({ acc, pathname, item, depth = 0, ...rest }) {
  const key = `${item.title}-${depth}`;

  if (item.items) {
    const open = true;

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        key={key}
        info={item.info}
        open={Boolean(open)}
        color={item.color}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        key={key}
        subItems={item.subItems}
        info={item.info}
        title={item.title}
        color={item.color}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles((theme) => ({
  mobileDrawer: {
    width: 256,
    backgroundColor: theme.palette.primary.main
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
    backgroundColor: theme.palette.primary.main
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2),
    width: 256,
    height: 'auto'
  }
}));

function NavBar({ openMobile, onMobileClose }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);

  let navConfigNew;
  if (user.user_type && user.user_type === 'HR') {
    navConfigNew = [
      {
        subheader: '',
        items: [
          {
            title: user?.companyName
              ? user?.companyName
              : t('language:navbar_title'),
            icon: HomeIcon,
            href: '/app/management/informatie',
            items: [
              {
                title: t('language:information'),
                icon: InfoIcon,
                color: '#faa619',
                href: '/app/management/informatie',
                subItems: [
                  {
                    title: t('language:actieve_medewerkers'),
                    href: '/app/management/informatie'
                  },
                  {
                    title: t('language:medewerkers'),
                    href: '/app/management/informatie'
                  },
                  {
                    title: t('language:informatie_ontwikkelingen'),
                    href: '/app/management/informatie'
                  },
                  {
                    title: t('language:instellingen'),
                    href: '/app/management/informatie'
                  }
                ]
              },
              {
                title: t('language:judge'),
                icon: ChecklistIcon,
                color: '#CC0000',
                href: '/app/management/beoordelen',
                subItems: [
                  {
                    title: t('language:voortgang_op_proces'),
                    href: '/app/management/beoordelen'
                  },
                  {
                    title: t('language:beoordeling_ontwikkeling'),
                    href: '/app/management/beoordelen'
                  },
                  {
                    title: t('language:organisatie_kpis'),
                    href: '/app/management/beoordelen'
                  }
                ]
              },
              {
                title: t('language:plan'),
                icon: TrendingUpIcon,
                color: '#01804a',
                href: '/app/management/plannen',
                subItems: [
                  {
                    title: t('language:lopende_ontwikkelingen'),
                    href: '/app/management/plannen'
                  },
                  {
                    title: t('language:educations'),
                    href: '/app/management/plannen'
                  },
                  {
                    title: t('language:suppliers'),
                    href: '/app/management/plannen'
                  }
                ]
              },
              {
                title: t('language:vacancies'),
                icon: FileTextIcon,
                color: '#56559a',
                href: '/app/management/vacatures',
                subItems: [
                  {
                    title: t('language:create_vacancy'),
                    href: '/app/management/vacatures'
                  },
                  {
                    title: t('language:occupancy_information'),
                    href: '/app/management/vacatures'
                  },
                  {
                    title: t('language:succesie_management'),
                    href: '/app/management/vacatures'
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  } else if (user.user_type && user.user_type === 'TAL') {
    navConfigNew = [
      {
        subheader: '',
        items: [
          {
            title: user?.companyName
              ? user.companyName
              : t('language:navbar_title'),
            icon: HomeIcon,
            href: '/app/talent/ontdekken',
            items: [
              {
                title: t('language:ontdekken'),
                icon: BinocularIcon,
                color: '#faa619',
                href: '/app/talent/ontdekken',
                subItems: [
                  {
                    title: t('language:persoonlijke_instellingen'),
                    href: `/app/social/profile`
                  },
                  {
                    title: t('language:talent_Profiel'),
                    href: `/app/talent/ontdekken/${subMenu.talentProfile}`
                  },
                  {
                    title: t('language:ambities'),
                    href: `/app/talent/ontdekken/${subMenu.ambities}`
                  },
                  {
                    title: t('language:navbar_doelen'),
                    href: `/app/talent/ontdekken/${subMenu.doelen}`
                  }
                ]
              },

              {
                title: t('language:navbar_ontwikkelen'),
                icon: SchoolIcon,
                color: '#01804a',
                href: '/app/talent/ontwikkelen',
                subItems: [
                  {
                    title: t('language:ontw_1'),
                    href: `/app/talent/ontwikkelen/${subMenu.jobfitness}`
                  },
                  {
                    title: t('language:ontw_2'),
                    href: `/app/talent/ontwikkelen/${subMenu.mijnLeerpad}`
                  },
                  {
                    title: t('language:ontw_3'),
                    href: `/app/talent/ontwikkelen/${subMenu.mijnAcademy}`
                  },
                  {
                    title: t('language:ontw_4'),
                    href: `/app/talent/ontwikkelen/${subMenu.mijnPerformance}`
                  }
                ]
              },
              {
                title: t('language:navbar_beoordelen'),
                icon: TrendingUpIcon,
                color: '#bf202b',
                href: '/app/talent/beoordelen',
                subItems: [
                  {
                    title: t('language:feedb_1'),
                    href: `/app/talent/beoordelen/${subMenu.selfAssessment}`
                  },
                  {
                    title: t('language:feedb_2'),
                    href: `/app/talent/beoordelen/${subMenu.managerAssessment}`
                  },
                  {
                    title: t('language:feedb_3'),
                    href: `/app/talent/beoordelen/${subMenu.gradenAssessment}`
                  },
                  {
                    title: t('language:feedb_4'),
                    href: `/app/talent/beoordelen/${subMenu.overzicht}`
                  }
                ]
              },
              {
                title: t('language:navbar_doorgroeien'),
                icon: FileTextIcon,
                color: '#56559a',
                href: '/app/talent/doorgroeien',
                subItems: [
                  {
                    title: t('language:interne_vacatures'),
                    href: '/app/talent/doorgroeien'
                  },
                  {
                    title: t('language:extern_vacatures'),
                    href: '/app/talent/doorgroeien'
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  } else if (user.user_type && user.user_type === 'MAN') {
    navConfigNew = [
      {
        subheader: '',
        items: [
          {
            title: user?.companyName
              ? user?.companyName
              : t('language:navbar_title'),
            icon: HomeIcon,
            href: '/app/management/informatie',
            items: [
              {
                title: t('language:information'),
                icon: InfoIcon,
                color: '#faa619',
                href: '/app/management/informatie',
                subItems: [
                  {
                    title: t('language:actieve_medewerkers'),
                    href: '/app/management/informatie'
                  },
                  {
                    title: t('language:medewerkers'),
                    href: '/app/management/informatie'
                  },
                  {
                    title: t('language:informatie_ontwikkelingen'),
                    href: '/app/management/informatie'
                  },
                  {
                    title: t('language:instellingen'),
                    href: '/app/management/informatie'
                  }
                ]
              },
              {
                title: t('language:judge'),
                icon: ChecklistIcon,
                color: '#CC0000',
                href: '/app/management/beoordelen',
                subItems: [
                  {
                    title: t('language:voortgang_op_proces'),
                    href: '/app/management/beoordelen'
                  },
                  {
                    title: t('language:beoordeling_ontwikkeling'),
                    href: '/app/management/beoordelen'
                  },
                  {
                    title: t('language:organisatie_kpis'),
                    href: '/app/management/beoordelen'
                  }
                ]
              },
              {
                title: t('language:plan'),
                icon: TrendingUpIcon,
                color: '#01804a',
                href: '/app/management/plannen',
                subItems: [
                  {
                    title: t('language:lopende_ontwikkelingen'),
                    href: '/app/management/plannen'
                  },
                  {
                    title: t('language:educations'),
                    href: '/app/management/plannen'
                  },
                  {
                    title: t('language:suppliers'),
                    href: '/app/management/plannen'
                  }
                ]
              }
            ]
          }
        ]
      }
    ];
  } else {
    navConfigNew = [
      {
        subheader: '',
        items: [
          {
            title: 'Role Management',
            href: '/app/management/role',
            icon: ShieldIcon
          },
          {
            title: 'Object Type',
            href: '/app/management/objectType',
            icon: EditIcon
          },
          {
            title: 'Company',
            href: '/app/system/companies/new',
            icon: UsersIcon
          },
          {
            title: 'Chat',
            href: '/app/chat',
            icon: MessageCircleIcon,
            info: () => <Chip color="" size="" label="" />
          },
          {
            title: 'Emily Message',
            href: '/app/system/emily-message',
            icon: MailOutlineIcon
          },
          {
            title: t('language:competence'),
            href: '/app/system/competence',
            icon: LabelImportantIcon
          },
          {
            title: t('language:role_skill'),
            href: '/app/system/role-skill',
            icon: AwardIcon
          }
        ]
      }
    ];
  }

  let navConfig = navConfigNew;

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const UserAvatar = () => {
    return (
      <Box p={2} pt={4}>
        <Box display="flex" justifyContent="center">
          <RouterLink to="/app/account">
            <Avatar
              alt="User"
              className={classes.avatar}
              src={apiService.avatarLink + '/' + user.avatarP}
            />
          </RouterLink>
        </Box>
        <Box mt={2} textAlign="center">
          <Link
            component={RouterLink}
            to="/app/account"
            variant="h5"
            color="textPrimary"
            style={{ color: '#c1c1c1' }}
            underline="none"
          >
            {`${user.username}`}
          </Link>
          <Typography variant="body2" style={{ color: '#fff' }}>
            {user.bio}
          </Typography>
        </Box>
      </Box>
    );
  };

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box display="flex" justifyContent="center">
            <RouterLink to="/">
              <Logo className={classes.logo} />
            </RouterLink>
          </Box>
        </Hidden>
        <Divider />
        <Box p={2}>
          {navConfig.map((config) => (
            <List
              key={config.subheader}
              subheader={
                <ListSubheader disableGutters disableSticky>
                  {config.subheader}
                </ListSubheader>
              }
            >
              {renderNavItems({
                items: config.items,
                pathname: location.pathname
              })}
            </List>
          ))}
        </Box>
      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default NavBar;
