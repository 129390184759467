import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles, Dialog, DialogContent } from '@material-ui/core';
import NavBar from './NavBar';
import TopBar from './TopBar';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useTheme } from '@material-ui/core/styles';
import '../../i18n';
import { useTranslation } from 'react-i18next';
const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256
    }
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto'
  },
  modalWidth: {
    minWidth: '70%'
  }
}));

function DashboardLayout({ children }) {
  const classes = useStyles();
  const theme = useTheme();
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const location = useLocation();
  const dispatch = useDispatch();
  const { help, user } = useSelector((state) => state.account);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    console.log('user: ', user);
    i18n.changeLanguage(user?.lang_type_code);
  }, []);

  const HelpDialog = () => {
    return (
      <Dialog
        onClose={(e) => {
          dispatch({ type: '@account/unset-help-modal' });
        }}
        aria-labelledby="customized-dialog-title"
        open={help.isModalOpen}
        classes={{ paperWidthSm: classes.modalWidth }}
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
        maxWidth="lg"
      >
        <DialogContent style={{ overflow: 'hidden' }}>
          <iframe src={help.HelpModal} width="1000" height="1200"></iframe>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        style={{
          backgroundColor:
            location.pathname.indexOf('management/informatie') > 0 ||
            location.pathname.indexOf('talent/ontdekken') > 0
              ? '#faa619'
              : location.pathname.indexOf('management/beoordelen') > 0
              ? '#CC0000'
              : location.pathname.indexOf('talent/ontwikkelen') > 0
              ? '#01804a'
              : location.pathname.indexOf('management/plannen') > 0
              ? '#01804a'
              : location.pathname.indexOf('talent/beoordelen') > 0
              ? '#CC0000'
              : location.pathname.indexOf('management/vacatures') > 0 ||
                location.pathname.indexOf('talent/doorgroeien') > 0
              ? '#56559a'
              : theme.palette.primary.main
        }}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>{children}</div>
          {HelpDialog()}
        </div>
      </div>
    </div>
  );
}

DashboardLayout.propTypes = {
  children: PropTypes.any
};

export default DashboardLayout;
