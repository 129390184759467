/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_CONTACTS,
  GET_THREADS,
  GET_THREAD,
  CREATE_GROUP,
  MARK_THREAD_AS_SEEN,
  ADD_MESSAGE,
  OPEN_SIDEBAR,
  CLOSE_SIDEBAR,
  ADD_CONTACT,
  INACTIVE_USER,
  ACTIVE_USER,
  GET_GROUPS,
  SHOW_CHAT_WITH_LAYOUT,
  SET_CURRENT_THREAD,
  SET_CURRENT_PROFILE_TAB
} from 'src/actions/chatActions';
import objFromArray from 'src/utils/objFromArray';
import moment from 'moment';
import profileTabs from 'src/constants/profileTabs';

const initialState = {
  contacts: {
    byId: {},
    allIds: []
  },
  threads: {
    byKey: {},
    allKeys: []
  },
  groups: [],
  sidebarOpen: false,
  chatWithLayout: false,
  currentThread: null,
  currentProfileTab: profileTabs.profile
};

const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CONTACTS: {
      const { contacts } = action.payload;

      return produce(state, draft => {
        draft.contacts.byId = objFromArray(contacts);
        draft.contacts.allIds = Object.keys(draft.contacts.byId);
      });
    }

    case GET_THREADS: {
      const { threads } = action.payload;

      return produce(state, draft => {
        if (threads) {
          draft.threads.byKey = objFromArray(threads, 'key');
          draft.threads.allKeys = Object.keys(draft.threads.byKey);
        }
      });
    }

    case GET_THREAD: {
      const { thread } = action.payload;

      return produce(state, draft => {
        draft.threads.byKey[thread.key] = thread;

        if (!draft.threads.allKeys.includes(thread.key)) {
          draft.threads.allKeys.push(thread.key);
        }
      });
    }

    case MARK_THREAD_AS_SEEN: {
      const { threadKey } = action.payload;

      return produce(state, draft => {
        const thread = draft.threads.byKey[threadKey];

        if (thread) {
          thread.unreadCount = 0;
        }
      });
    }

    // NOTE: Need to fix undread messages
    case ADD_MESSAGE: {
      const { threadKey, message, userId, otherUserId } = action.payload;

      return produce(state, draft => {
        let thread = draft.threads.byKey[threadKey];

        console.log('log: threadM', thread);

        if (!thread) {
          thread = {
            key: threadKey,
            messages: [message],
            participantIds: [otherUserId, userId],
            unreadCount: 0
          };

          draft.threads.byKey[threadKey] = thread;
          draft.threads.allKeys.push(threadKey);
        } else {
          thread.messages.push(message);
          if (userId != message.senderId && threadKey != draft.currentThread) {
            thread.unreadCount++;
          }
        }
      });
    }

    case CREATE_GROUP: {
      return produce(state, draft => {
        draft.groups = [];
      });
    }

    case GET_GROUPS: {
      // console.log(action.payload);
      let {
        groups: { groupThreads }
      } = action.payload;
      return produce(state, draft => {
        draft.groups = groupThreads;
      });
    }

    case OPEN_SIDEBAR: {
      return produce(state, draft => {
        draft.sidebarOpen = true;
      });
    }

    case CLOSE_SIDEBAR: {
      return produce(state, draft => {
        draft.sidebarOpen = false;
      });
    }

    case ADD_CONTACT: {
      const { contacts } = action.payload;

      return produce(state, draft => {
        draft.contacts.byId = objFromArray(contacts);
        draft.contacts.allIds = Object.keys(draft.contacts.byId);
      });
    }

    case INACTIVE_USER: {
      const { userId } = action.payload;

      return produce(state, draft => {
        let contact = draft.contacts.byId[userId];
        if (contact) {
          contact.lastActivity = moment()
            .toDate()
            .getTime();
          contact.isActive = false;
        }
      });
    }

    case ACTIVE_USER: {
      const { users } = action.payload;
      return produce(state, draft => {
        for (const key in users) {
          let userId = users[key];
          let contact = draft.contacts.byId[userId];
          if (contact) {
            contact.isActive = true;
          }
        }
      });
    }

    case SHOW_CHAT_WITH_LAYOUT: {
      const { chatLayout } = action.payload;

      return produce(state, draft => {
        draft.chatWithLayout = chatLayout;
      });
    }

    case SET_CURRENT_THREAD: {
      const { currentThread } = action.payload;

      return produce(state, draft => {
        draft.currentThread = currentThread;
      });
    }

    case SET_CURRENT_PROFILE_TAB: {
      const { tab } = action.payload;

      return produce(state, draft => {
        draft.currentProfileTab = tab;
      });
    }

    default: {
      return state;
    }
  }
};

export default chatReducer;
