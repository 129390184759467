let IPAddress = process.env.REACT_APP_PROD_API_URL;

if (process.env.NODE_ENV !== 'production') {
  IPAddress = process.env.REACT_APP_DEV_API_URL;
}

let OriginIPAddress = IPAddress;

IPAddress += 'users/chat/';

const chatAPIServices = {
  getcontacts: IPAddress + 'contacts',
  getthreads: IPAddress + `threads`,
  creategroup: IPAddress + 'group',
  addnewmessage: IPAddress + 'messages/new',
  chat: OriginIPAddress + 'chat',
  getgroups: IPAddress + 'groups',
  markThreadAsSeen: IPAddress + 'mark-as-seen',
  searchChatContact: OriginIPAddress + 'chat/search-chat-contact',
  addtoChat: OriginIPAddress + 'chat/add-to-chat'
};

export default chatAPIServices;
