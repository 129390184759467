/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  COVER_SAVE_SUCCESS,
  COVER_SAVE_FAIL,
  SET_HELP_MODAL,
  UNSET_HELP_MODAL
} from 'src/actions/accountActions';
import { AVATAR_UPDATE_SUCCESS } from '../actions/accountActions';

const initialState = {
  user: null,
  error: null
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
      });
    }

    case LOGIN_SUCCESS: {
      const { user } = action.payload;
      return produce(state, (draft) => {
        draft.user = user;
        if (draft.user && !draft.user.lang_type_code) {
          draft.user.lang_type_code = 'UK';
        }
        draft.help = {
          HelpModal: null,
          isModalOpen: false
        };
        draft.error = null;
      });
    }

    case LOGIN_FAILURE: {
      const { error } = action.payload;
      return produce(state, (draft) => {
        // Maybe store error
        draft.error = error;
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = null;
        draft.error = null;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;

      return produce(state, (draft) => {
        draft.user = user;
        if (draft.user && !draft.user.lang_type_code) {
          draft.user.lang_type_code = 'UK';
        }
        draft.help = {
          HelpModal: null,
          isModalOpen: false
        };
        draft.error = null;
      });
    }

    case UPDATE_PROFILE: {
      const { user } = action.payload;
      console.log('user: user from reducer', user);
      return produce(state, (draft) => {
        draft.user = user;
        if (draft.user && !draft.user.lang_type_code) {
          draft.user.lang_type_code = 'UK';
        }
      });
    }

    case COVER_SAVE_SUCCESS: {
      // console.log('testing cover');
      const { CoverPic } = action.payload;
      return produce(state, (draft) => {
        draft.user.CoverPic = CoverPic;
      });
    }

    case AVATAR_UPDATE_SUCCESS: {
      const { avatar } = action.payload;
      return produce(state, (draft) => {
        draft.user.avatarP = avatar;
      });
    }

    case SET_HELP_MODAL: {
      const { help } = action.payload;
      return produce(state, (draft) => {
        draft.help = help;
      });
    }

    case UNSET_HELP_MODAL: {
      return produce(state, (draft) => {
        draft.help = {
          HelpModal: null,
          isModalOpen: false
        };
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
