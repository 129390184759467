const tag = '@talent-profile/';

export const TalentProfileActions = {
  Initial: tag + 'initial',
  Initial_With_Error: tag + 'initial-with-error',
  Set_Octogram_URL: tag + 'set-octogram-url',
  Set_Initial: tag + 'set-initial',
  Set_Complevels: tag + 'set-complevels',
  Change_Complevel: tag + 'change-comlevel',
  Set_Profile_History: tag + 'set-profile-history',
  Change_Tab: tag + 'change-tab',
  Reset: tag + 'reset',
  Next: tag + 'next',
  Prev: tag + 'prev'
};

export const initialState = {
  userId: null,
  talentProfileHistories: [],
  talentProfile: null,
  selectedTalentProfile: null,
  octogramURL: null,
  talentProfileHistories: [],

  // Calculation related data
  otmDrijfverenData: [],
  otmLeerstijlData: [],

  // Other stuffs
  complevel: null,
  complevels: [],

  //Not in use
  otmOctogramData: [],

  // Controls
  isLoading: false,
  error: false,
  activeTab: '1',
  activeProfileIndex: -1
};

export const talentProfileReducer = (state, action) => {
  switch (action.type) {
    case TalentProfileActions.Initial:
      return { ...initialState };

    case TalentProfileActions.Initial_With_Error:
      return { ...initialState, error: true };

    case TalentProfileActions.Set_Octogram_URL:
      return { ...state, octogramURL: action.payload };

    case TalentProfileActions.Set_Initial:
      const {
        octogramURL,
        otmDrijfverenData,
        otmLeerstijlData,
        otmOctogramData,
        talentProfile,
        selectedTalentProfile
      } = action.payload;

      return {
        ...state,
        octogramURL,
        otmDrijfverenData,
        otmLeerstijlData,
        otmOctogramData,
        talentProfile,
        selectedTalentProfile
      };

    case TalentProfileActions.Set_Complevels:
      return { ...state, complevels: action.payload };

    case TalentProfileActions.Change_Complevel:
      return { ...state, complevel: action.payload };

    case TalentProfileActions.Set_Profile_History:
      return { ...state, talentProfileHistories: action.payload };

    case TalentProfileActions.Change_Tab:
      return { ...state, activeTab: action.payload };

    case TalentProfileActions.Reset:
      return {
        ...state,
        activeProfileIndex: -1,
        selectedTalentProfile: state.talentProfile,
        octogramURL: state.talentProfile?.talentOctogram || null
      };

    case TalentProfileActions.Next: {
      const index = state.activeProfileIndex + 1;
      if (index > state.talentProfileHistories.length) return state;

      return {
        ...state,
        selectedTalentProfile: state.talentProfileHistories[index],
        octogramURL:
          state.talentProfileHistories[index]?.talentOctogram || null,
        activeProfileIndex: index
      };
    }

    case TalentProfileActions.Prev: {
      const index = state.activeProfileIndex - 1;

      if (index < 0) return state;

      return {
        ...state,
        selectedTalentProfile: state.talentProfileHistories[index],
        octogramURL:
          state.talentProfileHistories[index]?.talentOctogram || null,
        activeProfileIndex: index
      };
    }

    default:
      return state;
  }
};
