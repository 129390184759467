/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  GET_NOTIFICATIONS,
  ADD_NOTIFICATIONS,
  REMOVE_NOTIFICATIONS
} from 'src/actions/notificationsActions';

const initialState = {
  notifications: []
};

const invitationNotificationDefault = {
  id: 'invitation._id',
  title: '',
  description: '',
  type: '',
  senderId: '',
  receiverId: '',
  status: ''
};

const notificationsReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_NOTIFICATIONS: {
      const { notifications } = action.payload;

      return produce(state, draft => {
        draft.notifications = notifications;
      });
    }

    case ADD_NOTIFICATIONS: {
      const { notification } = action.payload;
      return produce(state, draft => {
        let index = draft.notifications.findIndex(
          notif => notif.id == notification.id
        );

        if (index != -1) {
          draft.notifications[index] = notification;
        } else {
          draft.notifications.push(notification);
        }
      });
    }

    case REMOVE_NOTIFICATIONS: {
      const { threadKey } = action.payload;

      return produce(state, draft => {
        // Remove all notification with this id
        draft.notifications = draft.notifications.filter(
          notification => notification.id != threadKey
        );
      });
    }

    default: {
      return state;
    }
  }
};

export default notificationsReducer;
