const subMenu = {
  perosonalProfile: 'personal-profile',
  talentProfile: 'talent-profile',
  ambities: 'ambities',
  doelen: 'doelen',
  selfAssessment: 'zelf-assessment',
  managerAssessment: 'manager-assessment',
  gradenAssessment: 'graden-assessment',
  overzicht: 'overzicht',
  jobfitness: 'jobfitness',
  mijnLeerpad: 'mijn-leerpad',
  mijnAcademy: 'mijn-academy',
  mijnPerformance: 'mijn-performance'
};

export default subMenu;
