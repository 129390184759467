import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

const defaultRoutes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/Auth/LoginView'))
  },
  {
    exact: true,
    path: '/login-unprotected',
    component: lazy(() => import('src/views/Auth/LoginView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/Auth/RegisterView'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/verify-account',
    component: lazy(() => import('src/views/Auth/VerifyAccount'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/reset-password',
    component: lazy(() => import('src/views/Auth/ResetPassword'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/unblock-login',
    component: lazy(() => import('src/views/Auth/UnblockUser'))
  },
  {
    exact: true,
    path: '/register-unprotected',
    component: lazy(() => import('src/views/Auth/RegisterView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/management/role" />
      },
      {
        exact: true,
        path: '/app/social/feed',
        component: lazy(() => import('src/views/social/FeedView'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: () => <Redirect to="/app/social/profile" />
      },
      {
        exact: true,
        path: '/app/management/dashboard',
        component: lazy(() => import('src/views/management/DashBoardView'))
      },
      {
        exact: true,
        path: '/app/management/role',
        component: lazy(() => import('src/views/RoleView'))
      },
      {
        exact: true,
        path: '/app/management/objectType',
        component: lazy(() => import('src/views/system/ObjectType'))
      },
      {
        exact: true,
        path: ['/app/chat/new', '/app/chat/:threadKey'],
        component: lazy(() => import('src/views/chat/ChatView'))
      },
      {
        exact: true,
        path: '/app/chat',
        component: () => <Redirect to="/app/chat/new" />
      },

      {
        exact: true,
        path: [
          '/app/system/companies/new',
          '/app/system/companies/:companyId',
          '/app/system/companies/:companyId/users',
          '/app/system/companies/:companyId/object'
        ],
        component: lazy(() => import('src/views/system/Company'))
      },
      {
        exact: true,
        path: '/app/system/emily-message',
        component: lazy(() => import('src/views/system/EmilyMessage'))
      },
      {
        exact: true,
        path: '/app/system/competence',
        component: lazy(() => import('src/views/Competence'))
      },
      {
        exact: true,
        path: '/app/system/role-skill',
        component: lazy(() => import('src/views/RoleSkill'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/feedbacksubmission/:feedbackKey',
        component: lazy(() => import('src/views/pages/FeedbackSumission'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default defaultRoutes;
