import axios from 'src/utils/axios';
import Axios from 'axios';
import userAPIServices from 'src/services/userAPIServices';

export const GET_NOTIFICATIONS = '@notifications/get-notifications';
export const ADD_NOTIFICATIONS = '@notifications/add-notifications';
export const REMOVE_NOTIFICATIONS = '@notifications/remove-notifications';

export function getNotifications() {
  const request = axios.get('/api/notifications');

  return dispatch => {
    request.then(response =>
      dispatch({
        type: GET_NOTIFICATIONS,
        payload: response.data
      })
    );
  };
}

export function getMyInvitation(receiverId) {
  const request = Axios.get(userAPIServices.invitation);

  return request
    .then(result => {
      return result.data;
    })
    .catch(error => {
      throw error;
    });
}

export function updateInvitation(id, status, msg) {
  const request = Axios.patch(userAPIServices.invitation, {
    id,
    status,
    msg
  });

  return request
    .then(result => {
      return result.data;
    })
    .catch(error => {
      throw error;
    });
}

// export function addNotification(notification) {
//   // const request = Axios.post(apiService.addcontact, { userId, contactId });

//   return dispatch => {
//     request.then(response =>
//       dispatch({
//         type: ADD_NOTIFICATIONS,
//         payload: notification
//       })
//     );
//   };
// }
