import Axios from 'axios';
import { apiService } from 'src/services/APIServices';
import companyAPIServices from 'src/services/companyAPIServices';

export function getCompanyTalentSkills(userId) {
  let request;
  console.log(userId);
  request = Axios.get(`${apiService.getTalentSkills}/${userId}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//Save Owner
export function saveCompany(data) {
  let request;
  request = Axios.post(apiService.company, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//Update Owner
export function updateCompany({ _id, ...data }) {
  let request;
  request = Axios.patch(apiService.company + '/' + _id, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//SaveOwner In User
export function saveCompanyUser(data) {
  let request = Axios.post(apiService.companyUserSignup, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//Delete Owner
export function deleteCompany(companyId) {
  let request = Axios.delete(apiService.company, {
    data: { companyId: companyId }
  });
  return request
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompany() {
  let request;
  request = Axios.get(apiService.company);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyById(companyId) {
  let request;
  request = Axios.get(apiService.company + '/' + companyId);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyByEmail(email) {
  let request;
  request = Axios.post(apiService.isOwnerEmail, email);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function saveImage(formData) {
  // const request = Axios.post(apiService.imageUpload, { Files });
  const request = Axios.post(apiService.companyImageUpload, formData);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getImages(companyId) {
  const request = Axios.get(apiService.companyPicture + '/' + companyId);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// Create company organization

// createOrganization

export function createComapnyOrganization(detaills) {
  const request = Axios.post(apiService.createOrganization, detaills);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateCompanyOrganization(detaills) {
  const request = Axios.post(apiService.updateOrganization, detaills);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function deleteCompanyLogo(fileName, companyId) {
  const request = Axios.delete(apiService.companyImageUpload, {
    data: {
      fileName: fileName,
      companyId: companyId
    }
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// NOTE:
export function createComapnyTalent(data) {
  const request = Axios.post(companyAPIServices.talent, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateCompanyTalent(data) {
  const request = Axios.patch(companyAPIServices.talent, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addCompanyUserManagerHR(data) {
  const request = Axios.post(`${apiService.addManagerHR}`, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// get company organisations
export function getCompanyOrganisations() {
  const request = Axios.get(`${apiService.getCompanyOrganisations}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function editCompanyUser(data) {
  const request = Axios.patch(`${apiService.companyUser}/edit`, data);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyUsers(companyId) {
  const request = Axios.get(`${apiService.companyUser}/${companyId}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyUsersId(companyId) {
  const request = Axios.get(`${apiService.companyUserId}/${companyId}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyUserById(userId) {
  try {
    const request = Axios.get(`${apiService.getCompanyUserId}/${userId}`);
    return request
      .then((result) => {
        return result.data;
      })
      .catch((error) => {
        throw error;
      });
  } catch (err) {
    throw err;
  }
}

export function resetCompanyUserPassword(userId, data) {
  const request = Axios.patch(
    `${companyAPIServices.setPassword}/${userId}`,
    data
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function toggleUserBlock(userId, companyId, status) {
  const request = Axios.patch(
    `${apiService.companyUser}/${userId}/toggleblock`,
    { status, companyId }
  );
  return request
    .then((result) => {
      console.log('dev:', result);
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// company objects
export function getCompanyObjects(companyId) {
  const request = Axios.get(`${apiService.companyObject}/objects/${companyId}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// add object to company
export function addToCompanyObjects(companyId, data) {
  const request = Axios.patch(
    `${apiService.companyObject}/addobject/${companyId}`,
    data
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// add object to company
export function removeFromCompanyObjects(companyId, data) {
  const request = Axios.patch(
    `${apiService.companyObject}/removeobject/${companyId}`,
    data
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//save compony-roles
export function RoleSaveHr(data) {
  const request = Axios.post(apiService.saveRolesHr, data);
  return request
    .then((result) => {
      return result;
    })
    .catch((error) => {
      throw error;
    });
}
//add skills
export function skillsSubmit({
  skill_group,
  skill_name,
  skill_description,
  skill_kennis,
  skill_gedrag
}) {
  const request = Axios.post(apiService.saveSkill, {
    skill_group,
    skill_name,
    skill_description,
    skill_kennis,
    skill_gedrag
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//get role by company id
export function getRoleByCompanyId(companyId) {
  //  console.log(companyId)
  const request = Axios.get(`${apiService.getRoleByCompanyId}/${companyId}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}
//get role from RoleSkill

export function getRoleSkill() {
  const request = Axios.get(apiService.getRoleSkill);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}
//get roleSkill fromeach id
export function getRoleSkillfromEachId(ids) {
  const request = Axios.get(`${apiService.getroleSkillbyPerticulerId}/${ids}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//get role by table id
export function getPerticularRoleByTableId(id) {
  const request = Axios.get(`${apiService.getRoleByTableId}/${id}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}
//update roletable data
export function roletableUpdate(
  {
    role_id,
    role_name,
    role_description,
    role_educational_level,
    role_job_success,
    role_physical_mental_strength,
    fte,
    Vacatures,
    role_vriij_te_gebruiken,
    skill_id,
    hotSkill,
    mondatory,
    junior_level,
    medior_level,
    senior_level,
    role_skill
  },
  { skill_name, skill_description, skill_kennis, skill_gedrag }
) {
  // console.log(role_skill);
  let skill_kennis_new =
    skill_kennis &&
    skill_kennis.length > 0 &&
    skill_kennis.map((id) => {
      return {
        knowledge_id: id._id,
        mandatory: mondatory
      };
    });

  let skill_gedrag_new =
    skill_gedrag &&
    skill_gedrag > 0 &&
    skill_gedrag.map((id) => {
      return {
        competenceid: id._id,
        mandatory: mondatory
      };
    });

  const request = Axios.patch(`${apiService.getRoleByTableId}/${role_id}`, {
    role_id,
    role_name,
    role_description,
    role_educational_level,
    role_job_success,
    role_physical_mental_strength,
    fte,
    Vacatures,
    role_vriij_te_gebruiken,
    skill_id,
    hotSkill,
    mondatory,
    junior_level,
    medior_level,
    senior_level,
    // skill_name,
    skill_description,
    skill_kennis_new,
    skill_gedrag_new,
    role_skill
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

//update company_user_profile pic

export function CoverPicUpdate(user, file, config) {
  let formData = new FormData();
  //console.log(user);
  formData.append('attachments', file);
  const request = Axios.patch(
    `${apiService.updateCoverPic}/${user._id}`,
    formData,
    config
  );
  return request
    .then((result) => {
      // console.log(result.data);
      return result.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}
//update avatar
export function avatarPic(user, file, config) {
  let formData = new FormData();
  formData.append('avatar', file);
  const request = Axios.patch(
    `${apiService.updateAvatarPic}/${user._id}`,
    formData,
    config
  );
  return request
    .then((result) => {
      // console.log(result.data);
      return result.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function getTimeline(userId) {
  console.log(userId);
  const request = Axios.get(`${apiService.getTimeline}/${userId}`);
  return request
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => console.log(error.message));
}
// get talent suggetions
export function getTalentSuggetions(searchText) {
  const request = Axios.get(`${apiService.talent}/${searchText}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function getCompanyUserLog(userId) {
  const request = Axios.get(`${apiService.getCompanyUserLog}/${userId}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyDetails() {
  const request = Axios.get(`${apiService.getCompanyDetails}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyOrganisationDetailsById(organisationId) {
  const request = Axios.get(
    `${apiService.getCompanyOrganitionById}/${organisationId}`
  );
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getCurrentCompanyOrganisation() {
  const request = Axios.get(`${apiService.getCurrentCompanyOrganisation}`);
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function updatePerformanceQuestion(questiondata, questionkey) {
  console.log('Running');
  const request = Axios.post(`${apiService.updatePerformanceQuestion}`, {
    questiondata: questiondata,
    questionkey: questionkey
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateReviewQuestion(
  questiondata,
  questionkey,
  organisationId
) {
  const request = Axios.post(`${apiService.updateReviewQuestion}`, {
    questiondata: questiondata,
    questionkey: questionkey,
    organisationId: organisationId
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addPerformanceReview(user_id, PerformanceReview, status) {
  const request = Axios.post(`${apiService.addPerformanceReview}`, {
    user_id,
    PerformanceReview,
    status
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateTalentBeoordeling(
  editId,
  user_id,
  PerformanceReview,
  status
) {
  const request = Axios.post(`${apiService.updateTalentBeoordeling}`, {
    editId,
    user_id,
    PerformanceReview,
    status
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLatestSavedPerformanceReview(user_id, role_id) {
  const request = Axios.post(`${apiService.getLatestSavedPerformanceReview}`, {
    user_id,
    role_id
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getLatestFinalizePerformanceReview(user_id, role_id) {
  const request = Axios.post(
    `${apiService.getLatestFinalizePerformanceReview}`,
    {
      user_id,
      role_id
    }
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getPerformanceReviewByUserId(user_id) {
  const request = Axios.get(
    `${apiService.getPerformanceReviewByUserId}/${user_id}`
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyPerformanceReview() {
  const request = Axios.post(apiService.getCompanyPerformanceReview);
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}
export function filterCompanyPerformanceReview(reviewYear, period) {
  const request = Axios.post(apiService.filterCompanyPerformanceReview, {
    reviewYear,
    period
  });
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getPerfromanceReviewByYearAndPeriod(userId, year, period) {
  const request = Axios.post(apiService.getPerfromanceReviewByYearAndPeriod, {
    userId,
    year,
    period
  });
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getCompanySettingCountings() {
  const request = Axios.get(apiService.getCompanySettingCountings);
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getCompanyTalentsCounts() {
  const request = Axios.get(apiService.getCompanyTalentsCounts);
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getCompanyPerformanceReviewOrganisationWise(year, period) {
  const request = Axios.post(
    apiService.getCompanyPerformanceReviewOrganisationWise,
    { year, period }
  );
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function filterCompanyPerformanceReviewByOrganisationId(
  organisationId,
  year,
  period
) {
  const request = Axios.post(
    apiService.filterCompanyPerformanceReviewByOrganisationId,
    { organisationId, year, period }
  );
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getTalentDevelopmentProgress() {
  const request = Axios.get(apiService.getTalentDevelopmentProgress);
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function filterTalentDevelopmentProgressByOrganisation(organisationId) {
  const request = Axios.post(
    apiService.filterTalentDevelopmentProgressByOrganisation,
    { organisationId }
  );
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getTalentFinalizeReviewsByCompanyUserId(companyUserId) {
  const request = Axios.post(
    apiService.getTalentFinalizeReviewsByCompanyUserId,
    {
      companyUserId
    }
  );
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getJobFitInfoByCompanyUserId(companyUserId) {
  const request = Axios.post(apiService.getJobFitInfoByCompanyUserId, {
    companyUserId
  });
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getTalentByOrgIdAndRole(organisationId, roleId) {
  const request = Axios.post(apiService.getTalentByOrgIdAndRole, {
    organisationId,
    roleId
  });
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getUserLanguageByUserId(userId) {
  const request = Axios.post(apiService.getUserLanguageByUserId, {
    userId
  });
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getLanguageById(languageId) {
  const request = Axios.post(apiService.getLanguageById, {
    languageId
  });
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getCompanyTrainingModule() {
  const request = Axios.get(apiService.getCompanyTrainingModule);
  return request
    .then((result) => result.data)
    .catch((error) => {
      throw error;
    });
}

export function getTalentRoleInfoByRoleIdAndUserId(role_id, userId) {
  let request = Axios.post(`${apiService.getTalentRoleInfoByRoleIdAndUserId}`, {
    role_id: role_id,
    userId
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

// add talentAmbition
export function updateTalentAmbitionsByTalentId(data) {
  let request = Axios.post(
    `${apiService.updateTalentAmbitionsByTalentId}`,
    data
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyUsersOrganisationWise() {
  let request = Axios.post(apiService.getCompanyUsersOrganisationWise);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyRolesOrgWise() {
  let request = Axios.get(`${apiService.getCompanyRolesOrgWise}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getCompanyTalents() {
  let request = Axios.get(`${apiService.getCompanyTalents}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTalentRoleInfo(role_id, userId) {
  // console.log("role")
  let request = Axios.post(`${apiService.getTalentRoleInfo}`, {
    role_id: role_id,
    userId
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function testEmail() {
  let request = Axios.get(`${apiService.testEmail}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getJobFitInfoByCompany() {
  let request = Axios.get(`${apiService.getJobFitInfoByCompany}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTalentsProfile() {
  let request = Axios.get(`${apiService.getTalentsProfile}`);
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function addEmployeeKpi(talentKpi, company_user_id) {
  let request = Axios.post(`${companyAPIServices.addEmployeeKpi}`, {
    talentKpi,
    company_user_id
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function updateEmployeeKpi(talentKpis, company_user_id) {
  let request = Axios.patch(`${companyAPIServices.updateEmployeeKpi}`, {
    talentKpis,
    company_user_id
  });
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getJobFitInfoByCompanyUserIdAndRole(userId, roleId) {
  let request = Axios.post(
    companyAPIServices.getJobFitInfoByCompanyUserIdAndRole,
    {
      userId,
      roleId
    }
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function getTalentsProfileByOrganisation(org) {
  let request = Axios.get(
    `${companyAPIServices.getTalentsProfileByOrganisation}/${org}`
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}
export function getJobFitInfoByCompanyAndOrg(org) {
  let request = Axios.get(
    `${companyAPIServices.getJobFitInfoByCompanyAndOrg}/${org}`
  );
  return request
    .then((result) => {
      return result.data;
    })
    .catch((error) => {
      throw error;
    });
}

export function filterTimelineForSystem(searchParam) {
  const request = Axios.post(companyAPIServices.filterTimelineForSystem, {
    data: searchParam
  });
  return request
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => console.log(error.message));
}

export function getTalentsByCompanyId(companyId) {
  const request = Axios.get(
    `${companyAPIServices.getTalentsByCompanyId}/${companyId}`
  );
  return request
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => console.log(error.message));
}

export function createTimelineEmilyMessage(
  userId,
  subject,
  body,
  link,
  sender,
  urgence,
  company,
  talent
) {
  const request = Axios.post(companyAPIServices.createTimelineEmilyMessage, {
    userId,
    subject,
    body,
    link,
    sender,
    urgence,
    company,
    talent
  });
  return request
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => console.log(error.message));
}

//update avatar
export function uploadTimlineMedia(timelineId, companyId, file, config) {
  let formData = new FormData();
  formData.append('timelineMedia', file);
  const request = Axios.patch(
    `${companyAPIServices.timlineMedia}/${timelineId}/${companyId}`,
    formData,
    config
  );
  return request
    .then((result) => {
      // console.log(result.data);
      return result.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    });
}

export function markTimelineSeen(timelineId) {
  const request = Axios.get(
    `${companyAPIServices.markTimelineSeen}/${timelineId}`
  );
  return request
    .then((res) => {
      console.log(res);
      return res.data;
    })
    .catch((error) => console.log(error.message));
}

export async function uploadSupplierImage(companyId, file) {
  let formData = new FormData();
  //console.log(user);
  formData.append('supplierImage', file);
  const request = Axios.post(
    `${apiService.uploadSupplierImage}/${companyId}`,
    formData
  );
  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function createSupplier(companyId, data) {
  const request = Axios.post(`${apiService.createSupplier}/${companyId}`, data);
  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function getAllSuppliers(companyId) {
  const request = Axios.get(`${apiService.getAllSuppliers}/${companyId}`);
  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function removeSupplier(supplierId, companyId) {
  const request = Axios.delete(
    `${apiService.deleteSupplier}/${supplierId}/${companyId}`
  );
  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function updateSupplier(supplierId, data) {
  const request = Axios.patch(
    `${apiService.updateSupplier}/${supplierId}`,
    data
  );
  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}
