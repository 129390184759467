import React, { useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import Logo from 'src/components/Logo';
import ChooseLanguage from 'src/components/ChooseLanguage';
import { THEMES } from 'src/constants';
import Account from './Account';
import Contacts from './Contacts';
import Notifications from './Notifications';
import Search from './Search';
import Settings from './Settings';
import { useDispatch, useSelector } from 'react-redux';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import { showHelpModal } from 'src/actions/accountActions';

const useStyles = makeStyles(theme => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...(theme.name === THEMES.LIGHT
      ? {
          boxShadow: 'none',
          backgroundColor: theme.palette.primary.main
        }
      : {}),
    ...(theme.name === THEMES.ONE_DARK
      ? {
          backgroundColor: theme.palette.background.default
        }
      : {})
  },
  toolbar: {
    height: 64,
    paddingLeft: 0
  }
}));

function TopBar({ className, onMobileNavOpen, ...rest }) {
  const classes = useStyles();
  const { user } = useSelector(state => state.account);
  const dispatch = useDispatch();
  const [modalVal, setModalVal] = useState(null);
  useEffect(() => {
    if (user?.user_type && user.user_type === 'HR') {
      setModalVal('HR');
    } else if (user?.user_type && user.user_type === 'TAL') {
      setModalVal('TAL');
    } else if (user?.user_type && user.user_type === 'MAN') {
      setModalVal('MAN');
    }
  }, [user]);

  return (
    <AppBar className={clsx(classes.root, className)} {...rest}>
      <Toolbar className={classes.toolbar}>
        <Hidden lgUp>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="small">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
        </Hidden>
        <Hidden mdDown>
          <RouterLink to="/">
            <Logo style={{ width: 256, height: 'auto' }} />
          </RouterLink>
        </Hidden>
        <Box ml={2} flexGrow={1} />
        <IconButton
          color="inherit"
          onClick={() => dispatch(showHelpModal(modalVal))}
        >
          <HelpOutlineIcon />
        </IconButton>
        {/* <Search /> */}
        <Contacts />
        <Notifications />
        <Settings />
        <Box ml={2}>
          <Account />
        </Box>
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
