/* eslint-disable react/no-array-index-key */
import React, { Suspense, Fragment } from 'react';
import { Switch, Route } from 'react-router-dom';
import LoadingScreen from 'src/components/LoadingScreen';
import { useSelector } from 'react-redux';
import { managerRoutes, hrRoutes, talentRoutes, defaultRoutes } from './config';

const renderRoutes = (routes) =>
  routes ? (
    <Suspense fallback={<LoadingScreen />}>
      <Switch>
        {routes.map((route, i) => {
          const Guard = route.guard || Fragment;
          const Layout = route.layout || Fragment;
          const Component = route.component;

          return (
            <Route
              key={i}
              path={route.path}
              exact={route.exact}
              render={(props) => (
                <Guard>
                  <Layout>
                    {route.routes ? (
                      renderRoutes(route.routes)
                    ) : (
                      <Component {...props} />
                    )}
                  </Layout>
                </Guard>
              )}
            />
          );
        })}
      </Switch>
    </Suspense>
  ) : null;

function Routes() {
  const { user } = useSelector((state) => state.account);

  if (user?.user_type && user.user_type === 'HR') {
    return renderRoutes(hrRoutes);
  } else if (user?.user_type && user.user_type === 'TAL') {
    return renderRoutes(talentRoutes);
  } else if (user?.user_type && user.user_type === 'MAN') {
    return renderRoutes(managerRoutes);
  } else {
    return renderRoutes(defaultRoutes);
  }
}

export default Routes;
