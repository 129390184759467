export const invitationRequestText = {
  NL: 'wil graag contact via de chat functie.',
  UK: 'Invites you to join on chat.',
  FR: 'Invites you to join on chat.',
  GE: 'Invites you to join on chat.'
};

export const invitationAcceptedText = {
  NL: 'heeft de uitnodiging geaccepteert, jullie kunnen nu chatten.',
  UK: 'Accepted your invitation, join him in chats.',
  FR: 'Accepted your invitation, join him in chats.',
  GE: 'Accepted your invitation, join him in chats.'
};

export const invitationRejectdText = {
  NL: 'heeft de uitnodiging niet geaccepteert.',
  UK: 'Rejected your request to join in chat.',
  FR: 'Rejected your request to join in chat.',
  GE: 'Rejected your request to join in chat.'
};

export const invitationStatus = {
  accept: 'Accepted',
  reject: 'Rejected',
  default: 'Pending'
};
