let IPAddress = process.env.REACT_APP_PROD_API_URL;

if (process.env.NODE_ENV !== 'production') {
  IPAddress = process.env.REACT_APP_DEV_API_URL;
}

const companyAPIServices = {
  filterTalentDevelopmentProgressByOrganisation:
    IPAddress + 'filterTalentDevelopmentProgressByOrganisation',
  getTalentDevelopmentProgress: IPAddress + 'getTalentDevelopmentProgress',
  filterCompanyPerformanceReviewByOrganisationId:
    IPAddress + 'filterCompanyPerformanceReviewByOrganisationId',
  getCompanyPerformanceReviewOrganisationWise:
    IPAddress + 'getCompanyPerformanceReviewOrganisationWise',
  getCompanyTalentsCounts: IPAddress + 'getCompanyTalentsCounts',
  getCompanySettingCountings: IPAddress + 'getCompanySettingCountings',
  getPerfromanceReviewByYearAndPeriod:
    IPAddress + 'getPerfromanceReviewByYearAndPeriod',
  filterCompanyPerformanceReview: IPAddress + 'filterCompanyPerformanceReview',
  getCompanyPerformanceReview: IPAddress + 'getCompanyPerformanceReview',
  getPerformanceReviewByUserId: IPAddress + 'getPerformanceReviewByUserId',
  getLatestFinalizePerformanceReview:
    IPAddress + 'getLatestFinalizePerformanceReview',
  getLatestSavedPerformanceReview:
    IPAddress + 'getLatestSavedPerformanceReview',
  updateTalentBeoordeling: IPAddress + 'updateTalentBeoordeling',
  addPerformanceReview: IPAddress + 'addPerformanceReview',
  getTalentDevelopment: IPAddress + 'getTalentDevelopment',
  getCompanyOrganitionById: IPAddress + 'getCompanyOrganisationDetailsById',
  updatePerformanceQuestion: IPAddress + 'company/updatePerformanceQuestion',
  getCompanyDetails: IPAddress + 'getCompanyDetails',
  getTalentSkills: IPAddress + 'companyUser/talentskills',
  getCompanyUserLog: IPAddress + 'companyUserLog',
  getCompanyOrganisations: IPAddress + 'getCompanyOrganisations',
  updateAvatarPic: IPAddress + 'company/companyuser/avatar',
  updateCoverPic: IPAddress + 'company/companyuser',
  getRoleByTableId: IPAddress + 'company/company-roles',
  getroleSkillbyPerticulerId: IPAddress + 'skill/eachSkill',
  getRoleSkill: IPAddress + 'skill/roleskill',
  getRoleByCompanyId: IPAddress + 'company/companyId',
  saveRolesHr: IPAddress + 'company/company-roles',
  getRoleById: IPAddress + 'viewroleById',
  getRoleViewHr: IPAddress + 'viewrolehr',
  companyObject: IPAddress + 'companyObject',
  getCompanyUserId: IPAddress + 'getCompanyUserId',
  companyUserId: IPAddress + 'companyUserId',
  addManagerHR: IPAddress + 'companyUser/managerHR',
  companyUser: IPAddress + 'companyUser',
  saveSkill: IPAddress + 'saveskills',
  createOrganization: IPAddress + 'create/company/organization',
  updateOrganization: IPAddress + 'update/company/organization',
  getTalentFinalizeReviewsByCompanyUserId:
    IPAddress + 'getTalentFinalizeReviewsByCompanyUserId',
  getJobFitInfoByCompanyUserId: IPAddress + 'getJobFitInfoByCompanyUserId',
  getTalentByOrgIdAndRole: IPAddress + 'getTalentByOrgIdAndRole',
  getCompanyTrainingModule: IPAddress + 'getCompanyTrainingModule',
  addEmployeeKpi: IPAddress + 'addEmployeeKpi',
  updateEmployeeKpi: IPAddress + 'updateEmployeeKpi',
  getJobFitInfoByCompanyUserIdAndRole:
    IPAddress + 'getJobFitInfoByCompanyUserIdAndRole',
  getTalentsProfileByOrganisation:
    IPAddress + 'getTalentsProfileByOrganisation',
  getJobFitInfoByCompanyAndOrg: IPAddress + 'getJobFitInfoByCompanyAndOrg',
  talent: IPAddress + 'company/user/talent',
  setPassword: IPAddress + 'company/user/set-password',

  //timelines
  getTimeline: IPAddress + 'companyuser/timeline',
  filterTimelineForSystem: IPAddress + 'filterTimelineForSystem',
  getTalentsByCompanyId: IPAddress + 'getTalentsByCompanyId',
  createTimelineEmilyMessage: IPAddress + 'createTimelineEmilyMessage',
  timlineMedia: IPAddress + 'timlineMedia',
  markTimelineSeen: IPAddress + 'markTimelineSeen'
};

export default companyAPIServices;
