import { useSelector } from 'react-redux';

export default function useLanguage() {
  const { user } = useSelector((state) => state.account);
  const language = user
    ? user?.lang_type_code === 'UK'
      ? 'EN'
      : user?.lang_type_code
    : 'NL';

  return language;
}
