import axios from 'src/utils/axios';
import Axios from 'axios';
import { apiService } from 'src/services/APIServices';
import { v4 as uuidv4 } from 'uuid';
import moment from 'moment';
import objFromArray from 'src/utils/objFromArray';
import { Socket } from 'socket.io-client';
import io from 'socket.io-client';
import chatAPIServices from 'src/services/chatAPIServices';
import { ADD_NOTIFICATIONS } from './notificationsActions';
import notificationTypes from 'src/constants/notificationTypes';
import userAPIServices from 'src/services/userAPIServices';
import { getCompanyUserById } from './CompanyActions';
import LANGUAGE from 'src/constants/language';

export const GET_CONTACTS = '@chat/get-contacts';
export const GET_THREADS = '@chat/get-threads';
export const GET_THREAD = '@chat/get-thread';
export const CREATE_GROUP = '@chat/create-group';
export const MARK_THREAD_AS_SEEN = '@chat/mark-thread-as-seen';
export const ADD_MESSAGE = '@chat/add-message';
export const OPEN_SIDEBAR = '@chat/open-sidebar';
export const CLOSE_SIDEBAR = '@chat/close-sidebar';
export const ADD_CONTACT = '@chat/add-contact';
export const INACTIVE_USER = '@chat/inactive-user';
export const ACTIVE_USER = '@chat/active-user';
export const GET_GROUPS = '@chat/get-groups';
export const SHOW_CHAT_WITH_LAYOUT = '@chat/show-chat-with-layout';
export const SET_CURRENT_THREAD = '@set-current-thread';
export const SET_CURRENT_PROFILE_TAB = '@set-current-profile-tab';

var socket = Socket;

// Get Contacts list from user's address book
export function getContacts() {
  const request = Axios.get(chatAPIServices.getcontacts);

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: GET_CONTACTS,
        payload: response.data
      })
    );
  };
}

export function getThreads(userId, chatLayout = false, userLang) {
  const request = Axios.get(apiService.getthreads);

  return (dispatch) => {
    request.then((response) => {
      dispatch({
        type: GET_THREADS,
        payload: response.data
      });

      // Add unread message to notification section
      if (response.data) {
        const { threads } = response.data;
        if (threads?.length > 0) {
          let unreadMessagesCount = 0;
          threads.forEach((thread) => {
            thread.messages.forEach((message) => {
              if (message.status != 'Seen') unreadMessagesCount++;
            });
          });

          if (unreadMessagesCount) {
            dispatch({
              type: ADD_NOTIFICATIONS,
              payload: {
                notification: {
                  id: notificationTypes.unreadMessages,
                  title: LANGUAGE[userLang ? userLang : 'NL']?.new_message,
                  description: `${
                    LANGUAGE[userLang ? userLang : 'NL'].unread_message_1
                  } ${unreadMessagesCount} ${
                    LANGUAGE[userLang ? userLang : 'NL'].unread_message_2
                  }`,
                  type: notificationTypes.unreadMessages
                }
              }
            });
          }
        }
      }

      if (socket.connected == true) {
        socket.disconnect();
      }
      const token = localStorage.getItem('accessToken');

      if (process.env.NODE_ENV !== 'production') {
        socket = io.connect(process.env.REACT_APP_DEV_CHAT_URL, {
          query: 'token=' + token
        });
      } else {
        socket = io.connect(process.env.REACT_APP_PROD_CHAT_URL, {
          query: 'token=' + token
        });
      }

      socket.on('connect', () => {
        let rooms = [];
        if (response?.data?.threads)
          rooms = Object.keys(objFromArray(response?.data?.threads, 'key'));

        socket.emit('rooms', { rooms, userId });
        /*dispatch({
          type: ACTIVE_USER,
          payload: {
            users: data
          }
        });*/

        socket.emit('invitationRoom', {
          userId
        });
      });

      // Invitation part
      socket.on('invitationToClient', (data) => {
        if (data?.invitation) {
          const invitation = data.invitation;

          // get sender user information from companyUser collection
          getCompanyUserById(invitation.senderId).then((senderUser) => {
            // Create Notification object and add
            dispatch({
              type: ADD_NOTIFICATIONS,
              payload: {
                notification: {
                  id: invitation._id,
                  title: `${senderUser.first_name} ${senderUser.last_name}`,
                  description: invitation.msg,
                  type: notificationTypes.invitation,
                  senderId: invitation.senderId,
                  receiverId: invitation.receiverId,
                  status: invitation.status
                }
              }
            });
          });
        }
      });

      socket.on('msgToClient', (data) => {
        if (data.message.senderId != userId) {
          // Add new messages to notification if user is on chat
          const location = window.location.href;
          if (!chatLayout || !location.includes('chat'))
            dispatch({
              type: ADD_NOTIFICATIONS,
              payload: {
                notification: {
                  id: data.threadKey,
                  title: data.message.body,
                  senderId: data.message.senderId,
                  description: data.message.body,
                  type: 'new_message'
                }
              }
            });

          dispatch({
            type: ADD_MESSAGE,
            payload: {
              userId,
              ...data
            }
          });
        }
      });

      socket.on('online', (data) => {
        dispatch({
          type: ACTIVE_USER,
          payload: {
            users: data
          }
        });
      });

      socket.on('offline', (data) => {
        // console.log(data);

        dispatch({
          type: INACTIVE_USER,
          payload: {
            userId: data
          }
        });
      });
    });
  };
}

export function getThread(threadKey) {
  const request = axios.get('/api/chat/thread', {
    params: {
      threadKey
    }
  });

  return (dispatch) => {
    request.then((response) => {
      if (response.data.thread) {
        dispatch({
          type: GET_THREAD,
          payload: response.data
        });
      }
    });
  };
}

export function markThreadAsSeen(threadKey) {
  const request = Axios.get(`${chatAPIServices.markThreadAsSeen}/${threadKey}`);

  return (dispatch) => {
    request.then(() =>
      dispatch({
        type: MARK_THREAD_AS_SEEN,
        payload: {
          threadKey
        }
      })
    );
  };
}

export function addMessage({
  userId,
  threadKey,
  contentType,
  mediaType,
  file,
  body,
  config
}) {
  let fileUrl = '';

  if (file) {
    fileUrl = URL.createObjectURL(file);
  }
  const message = {
    id: uuidv4(),
    body,
    contentType,
    fileUrl,
    createdAt: moment().toDate().getTime(),
    senderId: userId,
    mediaType
  };

  const data = {
    threadKey,
    otherUserId: '122323323',
    message
  };
  //console.log(file);

  let formData = new FormData();
  formData.set('userId', userId);
  formData.set('threadKey', threadKey);
  formData.set('body', body);
  formData.set('contentType', contentType);
  formData.set('createdAt', message.createdAt);
  formData.set('mediaType', message.mediaType);
  formData.append('attachments', file);

  const request = Axios.post(apiService.addnewmessage, formData, config);
  if (file) {
    request.then((response) => {
      // if (response.data.mimetype && response.data.mimetype == 'image/png') {
      //   data.message.fileUrl = response.data.filename;
      // } else if (
      //   response.data.mimetype &&
      //   response.data.mimetype == 'image/jpeg'
      // ) {
      //   data.message.fileUrl = response.data.filename;
      // } else if (response.data.mimetype && response.data.mimetype == 'text') {
      //   data.message.fileUrl = '';
      // }
      if (response.data.fileUrl !== '')
        data.message.fileUrl = response.data.newMessage.fileUrl;

      socket.emit('msgToServer', data);
    });
  } else {
    request.then((response) => {
      console.log('log: add msg', response.data);
    });

    console.log('log: msgToServer', data);
    socket.emit('msgToServer', data);
  }

  /*return (dispatch) => {
    request.then((response) => dispatch({
      type: ADD_MESSAGE,
      payload: {
        userId,
        ...response.data
      }
    }));
  };*/

  return async (dispatch) => {
    return request.then((response) =>
      dispatch({
        type: ADD_MESSAGE,
        payload: {
          userId,
          ...data
        }
      })
    );
  };
}

export function addContact(userId, contactId) {
  const request = Axios.post(apiService.addcontact, { userId, contactId });

  return (dispatch) => {
    request.then((response) =>
      dispatch({
        type: ADD_CONTACT,
        payload: response.data
      })
    );
  };
}

export function createNewGroup(data) {
  const request = Axios.post(apiService.creategroup, data);
  return async (dispatch) => {
    return request.then((response) =>
      dispatch({
        type: ADD_CONTACT,
        payload: response.data
      })
    );
  };
}

export function getGroups() {
  const request = Axios.get(apiService.getgroups);
  return (dispatch) => {
    request.then((response) => {
      console.log(response.data);
      return dispatch({
        type: GET_GROUPS,
        payload: response.data
      });
    });
  };
}

export function openSidebar() {
  return {
    type: OPEN_SIDEBAR
  };
}

export function closeSidebar() {
  return {
    type: CLOSE_SIDEBAR
  };
}

export function layoutChat(value) {
  console.log(value);
  return async (dispatch) => {
    try {
      dispatch({
        type: SHOW_CHAT_WITH_LAYOUT,
        payload: {
          chatLayout: value
        }
      });
    } catch (error) {
      console.log(error);
    }
  };
}

export function goOffline() {
  if (socket.connected == true) {
    socket.disconnect();
  }
}

export function createInvitation(receiverId, msg) {
  const request = Axios.post(userAPIServices.invitation, { receiverId, msg });

  return request
    .then((result) => {
      const invitation = result.data;
      if (invitation) {
        socket.emit('invitationToServer', {
          invitation,
          notifyTo: invitation.receiverId
        });
      }
      return result.data;
    })
    .catch((error) => {
      console.log('log: error', error);
      throw error;
    });
}

export function handleInvitationUpdate(invitation, notifyTo) {
  socket.emit('invitationToServer', { invitation, notifyTo });
}

// export const imageUpdate = ( file ) => {

//   return async dispatch => {

//                   let formData = new FormData();
//                   formData.append('image',file);
//                   let option = {
//                       method : 'POST',
//                       body : formData
//                   };
//               try{
//                   let data = await (await fetch(`http://${HOST}:${PORT}${worker_links[2].upload}${getToken}`,
//                   option)).json();
//                   console.log(data);
//                   return ({
//                       type : IMAGE_UPDATE,
//                       payload : data[1][0]
//                   })
//               }
//               catch(err){
//                  return ({
//                      type : IMAGE_ERROR
//                  })
//               }
//           }
//           else {
//           dispatch(noToken());
//           }
//       }
//  }
export async function searchChatContact(companyId) {
  const request = Axios.get(
    `${chatAPIServices.searchChatContact}/${companyId}`
  );

  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}

export async function addtoChat(users) {
  const request = Axios.post(`${chatAPIServices.addtoChat}`, {
    users
  });

  try {
    const result = await request;
    return result.data;
  } catch (error) {
    return error.response.data;
  }
}
