import React, { createContext, useReducer } from 'react';
import {
  talentProfileReducer,
  initialState
} from 'src/reducers/contextReducers/talentProfileReducers.js';

export const TalentProfileContext = createContext();

export const TalentProfileContextProvider = ({ children, userId }) => {
  const [talentProfileData, dispatch] = useReducer(talentProfileReducer, {
    ...initialState,
    userId
  });

  return (
    <TalentProfileContext.Provider value={{ talentProfileData, dispatch }}>
      {children}
    </TalentProfileContext.Provider>
  );
};
