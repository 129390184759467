let IPAddress = process.env.REACT_APP_PROD_API_URL;

if (process.env.NODE_ENV !== 'production') {
  IPAddress = process.env.REACT_APP_DEV_API_URL;
}

IPAddress += 'users';

const userAPIServices = {
  getCompanyUsers: IPAddress + '/getCompanyUsers',
  invitation: IPAddress + '/invitation',
  unBlockUser: IPAddress + '/unblock',
  availableNickname: IPAddress + '/check-available-nickname',
  getUserRoles: IPAddress + '/get-user-roles'
};

export default userAPIServices;
