let IPAddress = process.env.REACT_APP_PROD_API_URL;

if (process.env.NODE_ENV !== 'production') {
  IPAddress = process.env.REACT_APP_DEV_API_URL;
}

export const apiService = {
  baseURL: IPAddress,
  login: IPAddress + 'users/login',
  logout: IPAddress + 'users/logout',
  resetpassword: IPAddress + 'users/auth/reset-password',
  forgotPassword: IPAddress + 'users/auth/forgot-password',
  unBlockUserSelf: IPAddress + 'users/unblockUser',
  checkusername: IPAddress + 'users/username_check',
  checkemail: IPAddress + 'users/email_check',
  signup: IPAddress + 'users/signup',
  updateprofile: IPAddress + 'profile/edit',
  verifyaccount: IPAddress + 'users/verify-account',
  dashboard: IPAddress + 'user/dashboard',
  getcontacts: IPAddress + 'users/chat/contacts',
  getthreads: IPAddress + `users/chat/threads`,
  creategroup: IPAddress + 'users/chat/group',
  addnewmessage: IPAddress + 'users/chat/messages/new',
  searchuser: IPAddress + 'users/search',
  createOrganization: IPAddress + 'create/company/organization',
  updateOrganization: IPAddress + 'update/company/organization',
  addcontact: IPAddress + 'users/addcontact',
  chat: IPAddress + 'chat',
  object: IPAddress + 'objects',
  getObjectType: IPAddress + 'objecttype',
  getObjectTypeById: IPAddress + 'objecttypebyid',
  userRole: IPAddress + 'userRoles',
  roleSkill: IPAddress + 'roleSkill',
  skillKnowledge: IPAddress + 'skillKnowledge',
  getSkills: IPAddress + 'getskills',
  saveSkill: IPAddress + 'saveskills',
  uploads: IPAddress + 'uploads',
  getgroups: IPAddress + 'users/chat/groups',
  getRolesbyFilter: IPAddress + 'getRolesByFilter',
  companyUser: IPAddress + 'companyUser',
  addManagerHR: IPAddress + 'companyUser/managerHR',
  companyUserId: IPAddress + 'companyUserId',
  getCompanyUserId: IPAddress + 'getCompanyUserId',
  companyObject: IPAddress + 'companyObject',
  getRoleViewHr: IPAddress + 'viewrolehr',
  getRoleById: IPAddress + 'viewroleById',
  saveRolesHr: IPAddress + 'company/company-roles',
  getRoleByCompanyId: IPAddress + 'company/companyId',
  getRoleSkill: IPAddress + 'skill/roleskill',
  getroleSkillbyPerticulerId: IPAddress + 'skill/eachSkill',
  getRoleByTableId: IPAddress + 'company/company-roles',
  updateCoverPic: IPAddress + 'company/companyuser',
  updateAvatarPic: IPAddress + 'company/companyuser/avatar',
  //getCover: IPAddress + 'company/getcover',
  addNewknowledge: IPAddress + 'role/knowledge',
  updateKnowledge: IPAddress + 'role/knowledge/update',
  company: IPAddress + 'company',
  getCompanyOrganisations: IPAddress + 'getCompanyOrganisations',
  cover: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/companyCover',
  avatarLink: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/avatar',
  //timeline-apis
  timelineMediaLink: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/timeline',
  getTimeline: IPAddress + 'companyuser/timeline',
  talent: IPAddress + 'talent',
  activeUser: IPAddress + 'users/active-users',
  getCompanyUserLog: IPAddress + 'companyUserLog',
  talentAmbition: IPAddress + 'talentAmbition',
  bhagpicLink: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/bhagpics',
  ambpicLink: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/ambpics',
  chatAttachmentLink: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/chat',
  roles: IPAddress + 'userRoles',
  getcompetences: IPAddress + 'getcompetence',
  talentDoelens: IPAddress + 'talentdoelen',
  talentSkills: IPAddress + 'talentskills',
  getCompanyRoles: IPAddress + 'getcompanyroles',
  getTalentProfile: IPAddress + 'getTalentProfile',
  getTalentSkills: IPAddress + 'companyUser/talentskills',
  getTalentSkillsWithName: IPAddress + 'talentskillswithname',
  getTalentDoelenById: IPAddress + 'getTalentDoelenById',
  addTalentDevelopment: IPAddress + 'addTalentDevelopment',
  getDevelopments: IPAddress + 'getDevelopments',
  updateTalentDevelopment: IPAddress + 'updateTalentDevelopment',
  updateDevelopmentStatus: IPAddress + 'updateDevelopmentStatus',
  closeTalentDevelopment: IPAddress + 'closeTalentDevelopment',
  addTalentFeedbackRequest: IPAddress + 'addTalentFeedbackRequest',
  getFeedbackRequests: IPAddress + 'getFeedbackRequests',
  getskillsbycompanyid: IPAddress + 'getskillsbycompanyid',
  getallskillsbycompanyid: IPAddress + 'getallskillsbycompanyid',
  getFeedbackByKey: IPAddress + 'getFeedbackByKey',
  updateFeedbackByKey: IPAddress + 'updateFeedbackByKey',
  getKnowledgeByCompanyId: IPAddress + 'getknowledgebycompanyid',
  getFeedbackById: IPAddress + 'getFeedbackById',
  getCompanyDetails: IPAddress + 'getCompanyDetails',
  updatePerformanceQuestion: IPAddress + 'company/updatePerformanceQuestion',
  getJobFitInfo: IPAddress + 'getJobFitInfo',
  updateReviewQuestion: IPAddress + 'organisation/updatePerformanceQuestion',
  getCompanyOrganitionById: IPAddress + 'getCompanyOrganisationDetailsById',
  getCurrentCompanyOrganisation:
    IPAddress + 'company-organization/current-organization',
  getTalentDevelopment: IPAddress + 'getTalentDevelopment',
  updateTalentFeedbackRequest: IPAddress + 'updateFeedbackRequest',
  addPerformanceReview: IPAddress + 'addPerformanceReview',
  updateTalentBeoordeling: IPAddress + 'updateTalentBeoordeling',
  getLatestSavedPerformanceReview:
    IPAddress + 'getLatestSavedPerformanceReview',
  getLatestFinalizePerformanceReview:
    IPAddress + 'getLatestFinalizePerformanceReview',
  getPerformanceReviewByUserId: IPAddress + 'getPerformanceReviewByUserId',
  getCompanyPerformanceReview: IPAddress + 'getCompanyPerformanceReview',
  filterCompanyPerformanceReview: IPAddress + 'filterCompanyPerformanceReview',
  getPerfromanceReviewByYearAndPeriod:
    IPAddress + 'getPerfromanceReviewByYearAndPeriod',
  getCompanySettingCountings: IPAddress + 'getCompanySettingCountings',
  getCompanyTalentsCounts: IPAddress + 'getCompanyTalentsCounts',
  getCompanyPerformanceReviewOrganisationWise:
    IPAddress + 'getCompanyPerformanceReviewOrganisationWise',
  getButtonLink: IPAddress + 'getButtonLink',
  filterCompanyPerformanceReviewByOrganisationId:
    IPAddress + 'filterCompanyPerformanceReviewByOrganisationId',
  getTalentDevelopmentProgress: IPAddress + 'getTalentDevelopmentProgress',
  filterTalentDevelopmentProgressByOrganisation:
    IPAddress + 'filterTalentDevelopmentProgressByOrganisation',
  getTalentFinalizeReviewsByCompanyUserId:
    IPAddress + 'getTalentFinalizeReviewsByCompanyUserId',
  getJobFitInfoByCompanyUserId: IPAddress + 'getJobFitInfoByCompanyUserId',
  getOctogramPersonalities: IPAddress + 'getOctogramPersonalities',
  getTalentByOrgIdAndRole: IPAddress + 'getTalentByOrgIdAndRole',
  getUserLanguageByUserId: IPAddress + 'getUserLanguageByUserId',
  getLanguageById: IPAddress + 'getLanguageById',
  getTrainingModule: IPAddress + 'getTrainingModule',
  getCompanyTrainingModule: IPAddress + 'getCompanyTrainingModule',
  getSystemAccessByUserId: IPAddress + 'getSystemAccessByUserId',
  getTalentRoleInfoByRoleIdAndUserId:
    IPAddress + 'getTalentRoleInfoByRoleIdAndUserId',
  updateTalentAmbitionsByTalentId:
    IPAddress + 'updateTalentAmbitionsByTalentId',
  getCompanyUsersOrganisationWise:
    IPAddress + 'getCompanyUsersOrganisationWise',
  getRoleSkillBySkillName: IPAddress + 'getRoleSkillBySkillName',
  getKnowledgeByCode: IPAddress + 'getKnowledgeByCode',
  getCompanyRoleByManager: IPAddress + 'manager/getCompanyRoles',
  getCompanyRolesOrgWise: IPAddress + 'getCompanyRolesOrgWise',
  getCompanyTalents: IPAddress + 'getCompanyTalents',
  getTalentRoleInfo: IPAddress + 'company/getTalentRoleInfo',
  testEmail: IPAddress + 'testEmail',
  getJobFitInfoByCompany: IPAddress + 'getJobFitInfoByCompany',
  objecttypeBytypecode: IPAddress + 'objecttypeBytypecode',
  getTalentsProfile: IPAddress + 'getTalentsProfile',
  verifyRoleSkill: IPAddress + 'verifyRoleSkill',
  uploadSupplierImage: IPAddress + 'upload-supplier-image',
  createSupplier: IPAddress + 'create-supplier',
  getAllSuppliers: IPAddress + 'get-all-suppliers',
  deleteSupplier: IPAddress + 'delete-supplier',
  updateSupplier: IPAddress + 'update-supplier',
  suppliersLink: IPAddress + process.env.REACT_APP_CLIENT_DIR + '/suppliers'
};
