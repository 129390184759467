import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles,
  Badge,
  ListItemSecondaryAction
} from '@material-ui/core';
import {
  Bell as BellIcon,
  Package as PackageIcon,
  MessageCircle as MessageIcon,
  Truck as TruckIcon,
  UserPlus as UserPluseIcon
} from 'react-feather';
import notificationTypes from 'src/constants/notificationTypes';
import profileTabs from 'src/constants/profileTabs';
import {
  SET_CURRENT_PROFILE_TAB,
  SET_CURRENT_THREAD
} from 'src/actions/chatActions';
import { useTranslation } from 'react-i18next';
import InvitationWidget from './InvitationWidget';
import {
  ADD_NOTIFICATIONS,
  getMyInvitation
} from 'src/actions/notificationsActions';
import { useLanguage, useUser } from 'src/hooks';

const iconsMap = {
  order_placed: PackageIcon,
  new_message: MessageIcon,
  unread_messages: MessageIcon,
  item_shipped: TruckIcon,
  invitation: UserPluseIcon
};

const useStyles = makeStyles((theme) => ({
  popover: {
    width: 320
  },
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  badge: {
    borderRadius: 105,
    marginTop: 12,
    marginRight: 6,
    color: '#000',
    background: '#fff'
  }
}));

function Notifications() {
  const classes = useStyles();
  const notifications = useSelector(
    (state) => state.notifications.notifications
  );
  const user = useUser();
  const language = useLanguage();
  const { contacts } = useSelector((state) => state.chat);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    _getMyInvitation();
  }, []);

  // const getMy
  const _getMyInvitation = () => {
    getMyInvitation().then((invitationsData) => {
      if (invitationsData.length > 0) {
        invitationsData.map((invitation) => {
          dispatch({
            type: ADD_NOTIFICATIONS,
            payload: {
              notification: invitation
            }
          });
        });
      }
    });
  };

  const getContactName = (threadKey) => {
    let contactList = Object.values(contacts.byId);

    let index = contactList.findIndex(
      (contact) => contact.conversationId == threadKey
    );

    return index != -1 ? contactList[index].name : t('language:unknown');
  };

  const getNotificationTitle = (notification) => {
    if (notification.type == notificationTypes.message) {
      return getContactName(notification.id);
    }

    return notification.title;
  };

  return (
    <>
      <Tooltip title={t('language:notifications')}>
        <Badge
          classes={{ badge: classes.badge }}
          badgeContent={notifications.length}
        >
          <IconButton color="inherit" ref={ref} onClick={handleOpen}>
            <SvgIcon>
              <BellIcon />
            </SvgIcon>
          </IconButton>
        </Badge>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Box p={2}>
          <Typography variant="h5" color="textPrimary">
            {t('language:notifications_title')}
          </Typography>
        </Box>
        {notifications.length === 0 ? (
          <Box p={2}>
            <Typography variant="h6" color="textPrimary">
              {t('language:no_notification_text')}
            </Typography>
          </Box>
        ) : (
          <>
            <List className={classes.list} disablePadding>
              {notifications.length > 0 &&
                notifications.map((notification) => {
                  const Icon = iconsMap[notification.type];

                  if (notification.type == notificationTypes.invitation) {
                    return (
                      <ListItem
                        className={classes.listItem}
                        divider
                        key={notification.id}
                      >
                        <InvitationWidget
                          Icon={Icon}
                          notification={notification}
                          languageTypeCode={language}
                        />
                      </ListItem>
                    );
                  }

                  return (
                    <ListItem
                      className={classes.listItem}
                      component={RouterLink}
                      divider
                      key={notification.id}
                      to={{
                        pathname: `/app/social/profile`,
                        state: {
                          currentTab: profileTabs.chat,
                          threadKey: notification.id
                        }
                      }}
                      onClick={() => {
                        dispatch({
                          type: SET_CURRENT_THREAD,
                          payload: { currentThread: notification.id }
                        });
                        dispatch({
                          type: SET_CURRENT_PROFILE_TAB,
                          payload: { tab: profileTabs.chat }
                        });
                        handleClose();
                      }}
                    >
                      <ListItemAvatar>
                        <Avatar className={classes.icon}>
                          <SvgIcon fontSize="small">
                            <Icon />
                          </SvgIcon>
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText
                        primary={getNotificationTitle(notification)}
                        primaryTypographyProps={{
                          variant: 'subtitle2',
                          color: 'textPrimary'
                        }}
                        secondary={notification.description}
                      />
                    </ListItem>
                  );
                })}
            </List>
            {/*
              <Box p={1} display="flex" justifyContent="center">
              <Button component={RouterLink} size="small" to="#">
                Mark all as read
              </Button>
            </Box>
            */}
          </>
        )}
      </Popover>
    </>
  );
}

export default Notifications;
