import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import GuestGuard from 'src/components/GuestGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import AuthGuard from 'src/components/AuthGuard';

const hrRoutes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/Auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/management/informatie" />
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/management/informatie',
        component: lazy(() => import('src/views/management/Information'))
      },
      {
        exact: true,
        path: '/app/management/beoordelen',
        component: lazy(() => import('src/views/management/Review'))
      },
      {
        exact: true,
        path: '/app/management/plannen',
        component: lazy(() => import('src/views/management/Plan'))
      },
      {
        exact: true,
        path: '/app/management/vacatures',
        component: lazy(() => import('src/views/management/Vacancy'))
      },
      {
        exact: true,
        path: '/app/management/company-users',
        component: lazy(() => import('src/views/CompanyUsers'))
      },
      {
        exact: true,
        path: '/app/management/competence',
        component: lazy(() => import('src/views/Competence'))
      },
      {
        exact: true,
        path: '/app/management/role',
        component: lazy(() => import('src/views/RoleView'))
      },
      {
        exact: true,
        path: '/app/management/role-skill',
        component: lazy(() => import('src/views/RoleSkill'))
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/feedbacksubmission/:feedbackKey',
        component: lazy(() => import('src/views/pages/FeedbackSumission'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default hrRoutes;
