import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';

const talentRoutes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/Auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: lazy(() => import('src/views/talent/Home'))
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: '/app/social',
        component: lazy(() => import('src/views/social/ProfileView'))
      },

      {
        exact: true,
        path: '/app/talent/ontdekken',
        component: lazy(() => import('src/views/talent/Explore'))
      },
      {
        exact: true,
        path: '/app/talent/ontdekken/:widget',
        component: lazy(() => import('src/views/talent/Explore'))
      },
      {
        exact: true,
        path: '/app/talent/ontwikkelen',
        component: lazy(() => import('src/views/talent/Development'))
      },
      {
        exact: true,
        path: '/app/talent/ontwikkelen/:widget',
        component: lazy(() => import('src/views/talent/Development'))
      },
      {
        exact: true,
        path: '/app/talent/beoordelen',
        component: lazy(() => import('src/views/talent/Feedback'))
      },
      {
        exact: true,
        path: '/app/talent/beoordelen/:widget',
        component: lazy(() => import('src/views/talent/Feedback'))
      },
      {
        exact: true,
        path: '/app/talent/doorgroeien',
        component: lazy(() => import('src/views/talent/Doorgroeien'))
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/feedbacksubmission/:feedbackKey',
        component: lazy(() => import('src/views/pages/FeedbackSumission'))
      },

      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default talentRoutes;
