const LANGUAGE = {
  NL: {
    new_message: 'Nieuwe berichten',
    unread_message_1: 'Je hebt',
    unread_message_2: 'nieuwe berichten.'
  },
  UK: {
    new_message: 'New messages',
    unread_message_1: 'You have',
    unread_message_2: 'unread messages.'
  },
  DUF: {
    new_message: 'Nieuwe berichten',
    unread_message_1: 'Je hebt',
    unread_message_2: 'nieuwe berichten.'
  },
  DUI: {
    new_message: 'Nieuwe berichten',
    unread_message_1: 'Je hebt',
    unread_message_2: 'nieuwe berichten.'
  }
};

export default LANGUAGE;
