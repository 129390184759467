import jwtDecode from 'jwt-decode';
import axios from 'axios';
import { apiService } from 'src/services/APIServices';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }
        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();
    if (!accessToken) {
      return;
    }
    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }

  loginWithEmailAndPassword = (username, password, role) =>
    new Promise((resolve, reject) => {
      axios
        .post(
          apiService.login,
          { username, password, role },
          { withCredentials: true }
        )
        .then((response) => {
          if (response.data.user) {
            this.setSession(response.data.token);
            resolve(response.data.user);
          } else {
            reject(response);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  loginInWithToken = () =>
    new Promise((resolve, reject) => {
      axios
        .get(apiService.dashboard, { withCredentials: true })
        .then((response) => {
          if (response.data.user) {
            resolve(response.data.user);
          } else {
            this.setSession(null);
            reject(response.data.error);
          }
        })
        .catch((error) => {
          reject(error);
        });
    });

  logout = () => {
    axios.get(apiService.logout);
    this.setSession(null);
  };

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('accessToken', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      axios.defaults.withCredentials = true;
    } else {
      localStorage.removeItem('accessToken');
      delete axios.defaults.headers.common.Authorization;
    }
  };

  getAccessToken = () => localStorage.getItem('accessToken');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }
    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;
    return decoded.exp > currentTime;
  };

  isAuthenticated = () => !!this.getAccessToken();
}

const authService = new AuthService();

export default authService;
