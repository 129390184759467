import React, { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSnackbar } from 'notistack';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  InputAdornment,
  Link,
  SvgIcon,
  TextField,
  Tooltip,
  Typography,
  makeStyles,
  ListItemText
} from '@material-ui/core';
import { Search as SearchIcon, XCircle as XIcon } from 'react-feather';
import axios from 'src/utils/axios';
import Axios from 'axios';
import { apiService } from 'src/services/APIServices';
import { addContact, getThreads } from 'src/actions/chatActions';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(() => ({
  drawer: {
    width: 500,
    maxWidth: '100%'
  }
}));

function Search() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [value, setValue] = useState('');
  const [isOpen, setOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isAdding, setAdding] = useState(false);
  const [results, setResults] = useState(null);
  const { enqueueSnackbar } = useSnackbar();
  const { user } = useSelector(state => state.account);
  const { t } = useTranslation();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleClick = e => {
    setAdding(true);
    console.log(user);
    console.log(e.currentTarget.dataset.userid);
    try {
      dispatch(addContact(user._id, e.currentTarget.dataset.userid));
      dispatch(getThreads(user._id), false, user?.lang_type_code);
      setOpen(false);
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    }
  };

  const handleSearch = async () => {
    try {
      setLoading(true);

      const response = await Axios.post(apiService.searchuser, { value });

      setResults(response.data.foundUsers);
    } catch (error) {
      enqueueSnackbar('Something went wrong', {
        variant: 'error'
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tooltip title="Search">
        <IconButton color="inherit">
          <SvgIcon fontSize="small">
            <SearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Drawer
        anchor="right"
        classes={{ paper: classes.drawer }}
        ModalProps={{ BackdropProps: { invisible: true } }}
        onClose={handleClose}
        open={isOpen}
        variant="temporary"
      >
        <PerfectScrollbar options={{ suppressScrollX: true }}>
          <Box p={3}>
            <Box
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Typography variant="h4" color="textPrimary">
                Search
              </Typography>
              <IconButton onClick={handleClose}>
                <SvgIcon fontSize="small">
                  <XIcon />
                </SvgIcon>
              </IconButton>
            </Box>
            <Box mt={2}>
              <TextField
                className={classes.queryField}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <SvgIcon fontSize="small" color="action">
                        <SearchIcon />
                      </SvgIcon>
                    </InputAdornment>
                  )
                }}
                onChange={event => setValue(event.target.value)}
                placeholder="Search people &amp; places"
                value={value}
                variant="outlined"
              />
            </Box>
            <Box mt={2} display="flex" justifyContent="flex-end">
              <Button
                color="secondary"
                variant="contained"
                onClick={handleSearch}
              >
                Search
              </Button>
            </Box>
            <Box mt={4}>
              {isLoading ? (
                <Box display="flex" justifyContent="center">
                  <CircularProgress />
                </Box>
              ) : (
                <>
                  {results &&
                    results.map(result => (
                      <Box mb={2} key={result._id}>
                        <ListItemText
                          primary={result.username}
                          primaryTypographyProps={{
                            noWrap: true,
                            variant: 'body2',
                            color: 'textPrimary'
                          }}
                        />
                        <Button
                          color="secondary"
                          variant="contained"
                          onClick={handleClick}
                          data-userid={result._id}
                        >
                          Add as contact
                        </Button>
                      </Box>
                    ))}
                </>
              )}
            </Box>
          </Box>
        </PerfectScrollbar>
      </Drawer>
    </>
  );
}

export default Search;
