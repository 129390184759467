import React from 'react';
import {
  ListItemAvatar,
  SvgIcon,
  ListItemText,
  Avatar,
  makeStyles,
  Box,
  Button
} from '@material-ui/core';
import Colors from 'src/constants/colors';
import { useTranslation } from 'react-i18next';
import {
  addContact,
  getThreads,
  handleInvitationUpdate
} from 'src/actions/chatActions';
import {
  REMOVE_NOTIFICATIONS,
  updateInvitation
} from 'src/actions/notificationsActions';
import {
  invitationAcceptedText,
  invitationRejectdText,
  invitationStatus
} from 'src/constants/invitation';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';

const useStyles = makeStyles(theme => ({
  icon: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.secondary.contrastText
  },
  accept: {
    background: Colors.orangeButton,
    color: Colors.orangeButtonText,
    '&:hover': {
      opacity: 0.85,
      background: Colors.orangeButton
    }
  },
  reject: {
    background: Colors.grayButton,
    color: Colors.grayButtonText,
    '&:hover': {
      opacity: 0.85,
      background: Colors.grayButton
    }
  }
}));

const InvitationWidget = ({ Icon, notification, languageTypeCode }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const { user } = useSelector(state => state.account);

  const acceptInvitation = () => {
    updateInvitation(
      notification.id,
      invitationStatus.accept,
      invitationAcceptedText
    ).then(invitation => {
      if (invitation && invitation.status === invitationStatus.accept) {
        // Add contact
        dispatch(addContact(notification.senderId, notification.receiverId));

        // Notifty to the sender
        handleInvitationUpdate(invitation, notification.senderId);

        // remove from notification
        dispatch({
          type: REMOVE_NOTIFICATIONS,
          payload: {
            threadKey: notification.id
          }
        });

        dispatch(getThreads('', false, user?.lang_type_code));

        enqueueSnackbar(t('language:invitation_accepted_text'), {
          variant: 'success',
          autoHideDuration: 3000
        });
      }
    });
  };

  const rejectInvitation = () => {
    updateInvitation(
      notification.id,
      invitationStatus.reject,
      invitationRejectdText
    ).then(invitation => {
      if (invitation && invitation.status === invitationStatus.reject) {
        // Notifty to the sender
        handleInvitationUpdate(invitation, notification.senderId);

        // remove from notification
        dispatch({
          type: REMOVE_NOTIFICATIONS,
          payload: {
            threadKey: notification.id
          }
        });

        enqueueSnackbar(t('language:invitation_rejected_text'), {
          variant: 'success',
          autoHideDuration: 3000
        });
      }
    });
  };

  const clearNotification = () => {
    dispatch({
      type: REMOVE_NOTIFICATIONS,
      payload: {
        threadKey: notification.id
      }
    });
  };

  const ActionButtons = () => {
    return (
      <Box display="flex" mt={2} mb={1} pl={7}>
        <Box>
          <Button
            variant="contained"
            className={classes.accept}
            size="small"
            onClick={acceptInvitation}
          >
            {t('language:accept_btn')}
          </Button>
        </Box>

        <Box ml={3}>
          <Button
            name="cancel"
            variant="contained"
            className={classes.reject}
            size="small"
            onClick={rejectInvitation}
          >
            {t('language:reject_btn')}
          </Button>
        </Box>
      </Box>
    );
  };

  const ClearButton = () => {
    return (
      <Box display="flex" justifyContent="flex-end">
        <Button variant="text" size="small" onClick={clearNotification}>
          {t('language:clear_btn')}
        </Button>
      </Box>
    );
  };

  return (
    <div>
      <Box display="flex" alignItems="center">
        <ListItemAvatar>
          <Avatar className={classes.icon}>
            <SvgIcon fontSize="small">
              <Icon />
            </SvgIcon>
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={notification.title}
          primaryTypographyProps={{
            variant: 'subtitle2',
            color: 'textPrimary'
          }}
          secondary={notification.description?.[languageTypeCode]}
        />
      </Box>

      {notification.status === invitationStatus.default ? (
        <ActionButtons />
      ) : (
        <ClearButton />
      )}
    </div>
  );
};

export default InvitationWidget;
