import React, { useRef, useState, useEffect } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import {
  Avatar,
  Box,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Popover,
  SvgIcon,
  Tooltip,
  Typography,
  makeStyles
} from '@material-ui/core';
import { Users as UsersIcon } from 'react-feather';
import OnlineIndicator from 'src/components/OnlineIndicator';
import {
  getContacts,
  SET_CURRENT_PROFILE_TAB,
  SET_CURRENT_THREAD
} from 'src/actions/chatActions';
import profileTabs from 'src/constants/profileTabs';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
  popover: {
    width: 320,
    padding: theme.spacing(2)
  },
  list: {
    padding: theme.spacing(1, 3)
  },
  listItemText: {
    marginRight: theme.spacing(1)
  },
  lastActivity: {
    whiteSpace: 'nowrap'
  },
  linkStyle: {
    textDecoration: 'none'
  }
}));

function Contacts() {
  const classes = useStyles();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const { contacts } = useSelector(state => state.chat);
  const [isOpen, setOpen] = useState(false);
  const { t } = useTranslation();

  useEffect(() => {
    return dispatch(getContacts());
  }, [dispatch]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title={t('language:contacts')}>
        <IconButton color="inherit" ref={ref} onClick={handleOpen}>
          <SvgIcon fontSize="small">
            <UsersIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
      <Popover
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        classes={{ paper: classes.popover }}
        anchorEl={ref.current}
        onClose={handleClose}
        open={isOpen}
      >
        <Typography variant="h4" color="textPrimary">
          {t('language:contacts')}
        </Typography>
        <Box mt={2}>
          <List disablePadding>
            {contacts.allIds.map(contactId => {
              const contact = contacts.byId[contactId];

              if (!contact.isGroupMember)
                return (
                  <ListItem disableGutters key={contact.id}>
                    <ListItemAvatar>
                      <Avatar alt="Person" src={contact?.avatar} />
                    </ListItemAvatar>
                    <ListItemText
                      className={classes.listItemText}
                      disableTypography
                      primary={
                        <Link
                          color="textPrimary"
                          component={RouterLink}
                          display="block"
                          underline="none"
                          noWrap
                          variant="h6"
                          to={{
                            pathname: `/app/social/profile`,
                            state: {
                              currentTab: profileTabs.chat,
                              threadKey: contact.conversationId
                            }
                          }}
                          onClick={() => {
                            dispatch({
                              type: SET_CURRENT_THREAD,
                              payload: { currentThread: contact.conversationId }
                            });
                            dispatch({
                              type: SET_CURRENT_PROFILE_TAB,
                              payload: { tab: profileTabs.chat }
                            });
                            handleClose();
                          }}
                        >
                          {contact.name}
                        </Link>
                      }
                    />
                    {contact.isActive ? (
                      <OnlineIndicator size="small" status="online" />
                    ) : (
                      <Typography
                        color="textSecondary"
                        noWrap
                        variant="caption"
                      >
                        {moment(contact.lastActivity).fromNow()}
                      </Typography>
                    )}
                  </ListItem>
                );
            })}
          </List>
        </Box>
      </Popover>
    </>
  );
}

export default Contacts;
