import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import AuthGuard from 'src/components/AuthGuard';
import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import GuestGuard from 'src/components/GuestGuard';

const managerRoutes = [
  {
    exact: true,
    path: '/',
    component: () => <Redirect to="/login" />
  },
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/pages/Error404View'))
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/Auth/LoginView'))
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/management/informatie" />
      },
      {
        exact: true,
        path: '/app/social/profile',
        component: lazy(() => import('src/views/social/ProfileView'))
      },
      {
        exact: true,
        path: [
          '/app/management/organisation',
          '/app/management/organisation/:organisationId'
        ],
        component: lazy(() => import('src/views/manager/Organization'))
      },
      {
        exact: true,
        path: [
          '/app/management/employees',
          '/app/management/employees/:employeeId'
        ],
        component: lazy(() => import('src/views/manager/Employees'))
      },
      {
        exact: true,
        path: '/app/management/employees/review/:employeeId',
        component: lazy(() =>
          import('src/views/manager/Employees/ReviewTalent')
        )
      },
      {
        exact: true,
        path: '/app/management/informatie',
        component: lazy(() => import('src/views/manager/Informatie'))
      },
      {
        exact: true,
        path: '/app/management/beoordelen',
        component: lazy(() => import('src/views/manager/Beoordelen'))
      },
      {
        exact: true,
        path: '/app/management/plannen',
        component: lazy(() => import('src/views/manager/Plan'))
      }
    ]
  },
  {
    path: '*',
    layout: MainLayout,
    routes: [
      {
        exact: true,
        path: '/feedbacksubmission/:feedbackKey',
        component: lazy(() => import('src/views/pages/FeedbackSumission'))
      },
      {
        component: () => <Redirect to="/404" />
      }
    ]
  }
];

export default managerRoutes;
